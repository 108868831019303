import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: '0px',
  },
  appBar: {
    backgroundColor: '#fafafa',
    boxShadow: 'none',
    borderBottom: 'solid 1px #f1f1f1',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
  },
  user: {
    color: '#000',
  },
  logo: {
    cursor: 'pointer',
    width: '150px',
    height: '50px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  boxContainer: {
    height: '100%',
    backgroundColor: '#F7FAFD',
  },
}));
