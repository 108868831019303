import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate } from 'react-router-dom';
import { isAuth, isUserAgent } from '../../utils';
import { isAllowCurrentStep } from '../../utils/stepper';
import {
  AUTH_ROUTE_TYPE,
  MAIN_ROUTE_TYPE,
  NOTAUTH_ROUTE_TYPE,
  ONLY_AGENTS_ROUTE_TYPE,
  ONLY_CLIENTS_ROUTE_TYPE,
} from '../../constants/routeTypes';

const isAllowed = (type, additionalData) => {
  switch (type) {
    case AUTH_ROUTE_TYPE:
      const { authProtected } = additionalData;
      return authProtected ? isAuth() : !isAuth();
    case NOTAUTH_ROUTE_TYPE:
      return true;
    case ONLY_AGENTS_ROUTE_TYPE:
      const isAgent = isUserAgent();
      return isAgent;
    case ONLY_CLIENTS_ROUTE_TYPE:
      const isNotAgent = !isUserAgent();
      return isNotAgent;
    case MAIN_ROUTE_TYPE:
    default:
      const { step } = additionalData;
      return isAllowCurrentStep(step);
  }
};

const getqueryParams = () => {
  const { search = '', pathname } = window.location;
  const queryParams = search ? `${search}${pathname ? `&pathname=${pathname}` : ''}` : '';
  return queryParams;
};

export const ProtectedRoute = ({
  children, redirectPath, type, additionalData,
}) => {
  if (!isAllowed(type, additionalData)) {
    const queryParams = getqueryParams();
    return <Navigate to={`${redirectPath}${queryParams}`} replace />;
  }
  return children || <Outlet />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  redirectPath: PropTypes.string,
  type: PropTypes.string.isRequired,
  additionalData: PropTypes.shape({
    step: PropTypes.number,
    authProtected: PropTypes.bool,
  }),
};

ProtectedRoute.defaultProps = {
  additionalData: {},
  redirectPath: '',
};
