/* eslint-disable max-len */
import React, {
  useState, useEffect, useContext,
} from 'react';

import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

import { StepperContext } from '../../../../../context/stepper';
import useLoading from '../../../../../hooks/useLoading';
import useStyles from './useStyles';
import { getCurrentStep, storeStepData } from '../../../../../utils/stepper';

import * as entityAPI from '../../../../../api/entity';
import * as unprotectedAPI from '../../../../../api/unprotected';

import {
  COMPANY,
  COTIZACION_ACCOUNT_INMOBILIARIA,
  CREATE_ACCOUNT_NAME,
  DATA_TABS_BESTS_MATRIZ_OPTIONS,
  FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA,
  PERFILADOR_VARIANTE_COTIZATION,
  PROFILING_COMPLETED,
  REJECTED,
} from '../../../../../constants';

import {
  getProcess,
  createTags,
  storeAuth,
  getFlowTypeHandler,
  updateCurrentPageOnLocalStep,
  updateCurrentPagePerfilamientoTag,
} from '../../../../../utils';

import { getFlowName } from '../../../../../utils/flows';

import useRoute from '../../../../../hooks/useRoute';
import { useProduct } from '../../../../../hooks/useProduct';
import { useFieldValues } from '../../../hooks/useFieldValues';

import {
  completeClientIniciaTramiteTag,
  createClientAccountFromLocalData, createProcess, loginByEmailAndPassword, saveFieldValueAgentSelected, saveFieldValueInmobiliaria, saveFieldValueNombreInmobiliaria, storeClientFieldsData, updateClientDataByAgentAssigned,
  updateProcessData,
} from '../../../shared/CreateClientAccount';

import { useProfiling } from '../../../../../hooks/useProfiling';
import { executeAutoNodes } from '../../../../../utils/api/nodes.utils';
import Input from '../../../../../components/Input';
import { GroupFields } from '../../../../../components';
import { getClientsFields } from '../../../../../utils/api/companyConfigs';
import {
  getPerfilamientoLeadId,
  getLocalEntityId,
  getLocalEntityName,
  storeExtraPerfimalientoLeadData,
  getPerfilamiendoLeadAgentAssigned,
} from '../../../../../storage';
import { saveManyFieldValues } from '../../../../../utils/api/fieldValues.utils';
import { isValueAValidCharacter } from '../../../../../utils/fields';
import { TabsContext } from '../../../../../context/tabs/tabsContext';
import useFetchPageData from '../../../../../hooks/useFetchPageData';
import useCreditProfilingCotization from '../../CreditProfilingPage/hooks/useCreditProfilingCotization';
import BaseCreditProfilingCotization from '../../CreditProfilingPage/BaseCreditProfilingCotization';
import { fetchRegisterFieldsFilledByLeadId } from '../../../../../api/chiplo';

const PASSWORD_CONFIRM = 'passwordConfirm';
const PHONE = 'phone';
const SECTION_FIELDVALUES_NAME = 'cotizacionInmobiliaria';

const defaultAgentInputData = {
  _id: 'agentFieldId',
  label: 'Nombre',
  required: false,
  type: 'lista',
  options: [],
  disabled: false,
};

export const CreditCotizationAccount = ({ goTo, createUpdateTag, goProfilingCompleted }) => {
  const { strategy: profilingStrategy } = useProfiling();
  const { initTabs } = useContext(TabsContext);

  const {
    navigateWithQueryParam,
  } = useRoute();

  const { state: { steps, current } } = useContext(StepperContext);
  const { isLoading, startLoading, endLoading } = useLoading();
  const { getDefaultProduct, getFlowType } = useProduct();

  const processId = getProcess();
  const leadId = getPerfilamientoLeadId();

  const defaultProduct = getDefaultProduct();
  const flowName = getFlowName() || defaultProduct.name;
  const { pageData, fetchPageData, setPageData } = useFetchPageData({
    pageName: COTIZACION_ACCOUNT_INMOBILIARIA,
    flowName,
    autoFetch: false,
  });

  const {
    autoNodes,
    startNotifyResults,
    fetchAutoNodesToNotifyResults,
    fetchAutoNodes,
  } = useCreditProfilingCotization();

  const [agentInputData, setAgentInputData] = useState(defaultAgentInputData);
  const [agentsList, setAgentsList] = useState([]);
  const [agentSelected, setAgentSelected] = useState('');
  const [sections, setSections] = useState([]);
  const [show, setShow] = useState(false);

  const [checkSendEmail, setCheckSendEmail] = useState(false);

  const {
    localFieldValues,
    formatFieldValuesToUpload,
    fillFieldValuesFromStoredData,
    storeLocalFieldValueBySection,
  } = useFieldValues({ company: COMPANY, process: processId, customSectionName: SECTION_FIELDVALUES_NAME });

  const classes = useStyles();

  const { _id: productId, flow: flowId } = getDefaultProduct();

  const isNotCompleted = () => sections.some((section) => {
    const { fields = [] } = section;
    return fields.some(({ config = {}, value = '', status = {} }) => {
      const { value: statusValue } = status;
      const { required = false, hide = false } = config;
      if (!hide && !required && statusValue === REJECTED) return true;
      if (hide || !required) return false;
      return (!isValueAValidCharacter(value) || statusValue === REJECTED);
    });
  });

  const getCorrectPhone = (phoneText, addPhonePrefix) => (addPhonePrefix ? `+521${phoneText}` : phoneText);

  const transformDataToUpload = ({ filterFields, addPhonePrefix = false }) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      _id, config, value, fieldCatalog, name: fieldName,
    }) => {
      const correctPhoneValue = value;
      return {
        _id,
        config,
        value: fieldName === PHONE ? getCorrectPhone(correctPhoneValue, addPhonePrefix) : value,
        fieldCatalog: {
          _id: fieldCatalog?._id || '',
        },
      };
    });
    return {
      company: COMPANY,
      fields: newFields,
    };
  };

  const getAgentSelected = () => {
    const agentSelect = agentsList.find(({ fullName }) => fullName === agentSelected);
    const { _id, fullName } = agentSelect;
    return { _id, fullName };
  };

  const handleFinishPage = () => {
    const currentPage = PROFILING_COMPLETED;
    const localStepPosition = getCurrentStep();
    updateCurrentPageOnLocalStep(localStepPosition, currentPage);
    goProfilingCompleted();
  };

  const saveClientAgentAssigned = async ({ client, newProcess, token }) => {
    if (!agentSelected) return;
    const { _id: agentId, fullName: agentFullName } = getAgentSelected();
    await updateClientDataByAgentAssigned({
      client, agentId, process: newProcess, token,
    });
    await saveFieldValueAgentSelected(agentFullName, newProcess._id, token);
  };

  const execFinishCreateAccountAndProcess = async () => {
    const newClientData = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM], addPhonePrefix: true });
    const { fields: sharedFieldValues } = transformDataToUpload({ filterFields: [PASSWORD_CONFIRM], usePhoneValueFromFields: true });
    storeClientFieldsData(newClientData, sharedFieldValues);
    const { client, password } = await createClientAccountFromLocalData();
    const { email } = client;
    const user = await loginByEmailAndPassword(email, password);
    const { token, firstName, lastName } = user;
    const { process: processCreated } = await createProcess({
      client, token, productId, flow: flowId, company: COMPANY,
    });

    const { _id: newProcessId } = processCreated;

    const { normalizedFlowName } = await getFlowTypeHandler(newProcessId, token);
    const tagsCreated = await createTags(newProcessId, normalizedFlowName, CREATE_ACCOUNT_NAME, token);
    const newTags = updateCurrentPagePerfilamientoTag(tagsCreated, PROFILING_COMPLETED);
    const newProcess = { ...processCreated, tags: newTags };
    const inmobiliaria = getLocalEntityId();
    const inmobiliariaName = getLocalEntityName();
    await updateProcessData({ process: newProcess, token });
    await saveClientAgentAssigned({ client, newProcess, token });
    if (inmobiliaria) {
      await saveFieldValueInmobiliaria(inmobiliaria, newProcessId, token);
      await saveFieldValueNombreInmobiliaria(inmobiliariaName, newProcessId, token);
    }
    if (leadId) {
      storeExtraPerfimalientoLeadData({ processCreated: newProcessId });
    }
    await completeClientIniciaTramiteTag(newProcessId, token);
    const fieldValuesToSave = formatFieldValuesToUpload(localFieldValues, newProcessId);
    await saveManyFieldValues(fieldValuesToSave, token);
    await executeAutoNodes(autoNodes, newProcessId, token);
    await startNotifyResults(checkSendEmail, newProcessId, token);
    storeAuth({ firstName, lastName });
    handleFinishPage();
  };

  const execFinishByType = async () => {
    switch (pageData.finishType) {
      case FINISH_TYPE_CREDIT_PROFILLING_VIVIENDA:
      default:
        return execFinishCreateAccountAndProcess();
    }
  };

  const onSubmit = async () => {
    try {
      startLoading();
      if (isNotCompleted()) return;
      await execFinishByType();
      endLoading();
    } catch (e) {
      console.log('🚀 ~ onSubmit ~ e:', e);
      endLoading();
    }
  };

  const getFieldsData = (fieldsIds) => fieldsIds.map((fieldId) => unprotectedAPI.getById(fieldId));

  const setDefaultValues = (fields) => fields.map((field) => {
    const { value = '', config = {} } = field;
    return !value && config?.defaultValue
      ? { ...field, value: config?.defaultValue }
      : { ...field, value };
  });

  const fillFieldSectionsByLeadId = async (arrayFields, lead, flow) => {
    const filledFields = await fetchRegisterFieldsFilledByLeadId(lead, flow, COMPANY);
    return arrayFields.map((field) => {
      const { _id } = field;
      const filledField = filledFields.find(({ _id: fieldId }) => fieldId === _id);
      const { value = '' } = filledField || {};
      return filledField
        ? { ...field, value }
        : field;
    });
  };

  const getFieldsStructure = async (title, fieldsData) => {
    const fieldsDataWithValues = setDefaultValues(fieldsData);
    const fieldsWithExistingValues = await fillFieldValuesFromStoredData(fieldsDataWithValues);

    return [{
      fields: fieldsWithExistingValues,
      title,
    },
    ];
  };

  const fetchRegisterFields = async () => {
    console.log('🚀 ~ fetchRegisterFields ~ leadId:', leadId);
    const flowType = await getFlowType(flowName);
    const { fields, title } = await getClientsFields(flowType);
    const arrayFields = await Promise.all(getFieldsData(fields));
    if (!leadId) {
      const fieldsStructure = await getFieldsStructure(title, arrayFields);
      setSections(fieldsStructure);
    } else {
      const fieldsData = await fillFieldSectionsByLeadId(arrayFields, leadId, flowType);
      console.log('🚀 ~ fetchRegisterFields ~ fieldsData:', fieldsData);
      const fieldsStructure = await getFieldsStructure(title, fieldsData);
      setSections(fieldsStructure);
    }
  };

  const fetchAgentsByEntity = async () => {
    const entityId = getLocalEntityId();
    const agentsDataList = await entityAPI.getAgentsListByCompanyAndEntityId(COMPANY, entityId);
    const agentsListNames = agentsDataList.map(({ fullName }) => ({ name: fullName }));
    const newOptions = [{ name: '' }, ...agentsListNames];
    setAgentInputData((prevData) => ({ ...prevData, options: newOptions }));
    setAgentsList(agentsDataList);
  };

  const fetchAgentAssigned = async (agentAssignedId) => {
    const agent = await entityAPI.getAgentDataById(COMPANY, agentAssignedId);
    console.log('🚀 ~ fetchAgentAssigned ~ agent:', agent);
    const { fullName = '' } = agent;
    const agentsListNames = [{ name: '' }, { name: fullName }];
    setAgentInputData((prevData) => ({
      ...prevData,
      options: agentsListNames,
      disabled: true,
      value: fullName,
    }));
    setAgentsList([agent]);
  };

  const fetchAgentsField = async () => {
    const agentAssignedId = getPerfilamiendoLeadAgentAssigned();
    console.log('🚀 ~ fetchAgentsField ~ agentAssignedId:', agentAssignedId);
    if (leadId && agentAssignedId) return fetchAgentAssigned(agentAssignedId);
    return fetchAgentsByEntity();
  };

  const onChangeCheckSendEmail = () => {
    setCheckSendEmail(!checkSendEmail);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const _pageConfig = await fetchPageData();

      const { autonodes = [], autoNodesToNotifyResults = [] } = _pageConfig;
      await fetchAutoNodes(autonodes);
      await fetchAutoNodesToNotifyResults(autoNodesToNotifyResults);

      await fetchRegisterFields();
      await fetchAgentsField();
      setPageData((prevPageData) => ({
        ...prevPageData,
        ..._pageConfig,
        title: prevPageData?.title || _pageConfig?.title,
        infoTitle: prevPageData?.subtitle ? '' : profilingStrategy.getInfoTitle(_pageConfig),
        buttonTitle: _pageConfig?.buttonTitle,
        subtitle: prevPageData?.subtitle || _pageConfig?.subtitle || '',
        componentTitle: prevPageData?.subtitle ? '' : prevPageData?.componentTitle || _pageConfig?.componentTitle,
        finishType: _pageConfig?.finishType || pageData.finishType,
        buttonFooterTitle: _pageConfig?.buttonFooterTitle || '',
        tagNameConfig: _pageConfig?.tagNameConfig || pageData.tagNameConfig,
      }));
      setShow(true);
      endLoading();
    } catch (e) {
      console.log('🚀 ~ fetchData ~ e:', e);
      endLoading();
    }
  };

  const onChangeAgent = (_, value) => {
    const newFieldValue = { ...agentInputData, value };
    setAgentSelected(value);
    storeLocalFieldValueBySection(newFieldValue);
  };

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => {
        if (field.name === name) {
          const newFieldValue = { ...field, value, errorMessage: '' };
          storeLocalFieldValueBySection(newFieldValue);
          return newFieldValue;
        }
        return field;
      });
      const newSection = { ...section, fields: newFields };
      return newSection;
    });
    setSections(newSections);
  };

  const fillAgentSelectedValueByLocalValue = async () => {
    if (agentsList.length) {
      const newAgentField = await fillFieldValuesFromStoredData(agentInputData);
      const { value = '' } = newAgentField;
      setAgentInputData(newAgentField);
      setAgentSelected(value);
    }
  };

  useEffect(() => {
    initTabs({
      tabs: DATA_TABS_BESTS_MATRIZ_OPTIONS,
      default: DATA_TABS_BESTS_MATRIZ_OPTIONS[0].value,
      variant: PERFILADOR_VARIANTE_COTIZATION,
    });
    fetchData();
  }, []);

  useEffect(() => {
    if (steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigateWithQueryParam(steps[current + 1].route, 'inmobiliaria');
    }
  }, [steps, current]);

  useEffect(() => {
    fillAgentSelectedValueByLocalValue();
  }, [agentsList]);

  return (
    <BaseCreditProfilingCotization
      isLoading={isLoading}
      handleFinishPage={onSubmit}
      goTo={goTo}
      pageData={pageData}
      disabled={isNotCompleted()}
      autoNodes={autoNodes}
      showComponent={show}
      profilingStrategy={profilingStrategy}
      createUpdateTag={createUpdateTag}
      checkSendEmail={checkSendEmail}
      onChangeCheckSendEmail={onChangeCheckSendEmail}
    >
      <Typography
        component="h2"
        className={classes.messageWhats}
      >
        {pageData.agentTitle}
      </Typography>
      {agentInputData.options.length && (
        <Input
          label={agentInputData.label}
          type={agentInputData.type}
          required={agentInputData.required}
          options={agentInputData.options}
          disabled={agentInputData.disabled}
          onChange={onChangeAgent}
          value={agentSelected}
          classNameSelect={classes.inputField}
        />
      )}
      {sections.map(({ fields, title }, index) => (
        <GroupFields
          key={title}
          fields={fields}
          title={title}
          position={index}
          onChange={handleChange}
          extraInputClassName={classes.inputField}
        />
      ))}
    </BaseCreditProfilingCotization>
  );
};

CreditCotizationAccount.propTypes = {
  goTo: PropTypes.func.isRequired,
  createUpdateTag: PropTypes.func.isRequired,
  goProfilingCompleted: PropTypes.func.isRequired,
};
