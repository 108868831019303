const { REACT_APP_BASE_URL_MODAL } = process.env;
export const COMPANY_TYPE = 'Client fields';
export const AGENT_CLIENT_COMPANY_TYPE = 'Agent Client fields';
export const AGENT_FIELDS_COMPANY_TYPE = 'Agent fields';
export const BANCOS_CONDITIONS = 'BancosConditions';
export const FIADOR_COMPANY_TYPE = 'fiador nodes';
export const COMPANY = '64b19662c1bedd64021fd571';
export const COTIZACION = 'cotizacionExample';
export const TAGS_TYPE = '63d159310dae0d4059ba69a3';
export const CASH_TABLES = '63efbf974ddc26484b3044fc';
export const INVOICES_CALCULATIONS_FACTORAJE = '63daa121d1ce3542c7d740b7';
export const PAGES_DATA = '645d9f9254d569478b06b76e';
export const FLOW_CREDITO_SIMPLE = '62ccbe0ad13d1da220c4e43a';
export const FLOW_FACTORAJE_FINANCIERO = '62ffe802cbaff5221fe848ed';
export const BASE_URL_MODAL = REACT_APP_BASE_URL_MODAL;
export const EMAIL_TO_SEND = 'Noreply.CashByMega@gfmega.com';
export const MEGA_STEP_PRECALIFICATION = 'Precalificación';
export const MEGA_NODE_DOMICILIO_EMPRESA = 'Domicilio de la empresa';
export const MEGA_NODE_DOMICILIO_FISCAL = 'Domicilio Fiscal';
export const MEGA_NODE_ACTIVOS_AVAL_BIENES_INMUEBLES = 'Activos Aval Bienes Inmuebles (Casas, Terrenos, Naves Industriales, Oficinas, etc)';
export const MEGA_NODE_ACTIVOS_AVAL_BIENES_MUEBLES = 'Activos Aval Bienes Muebles (Autos, Joyas, Obras de Arte, Mobiliario, etc.)';
export const MEGA_NODE_ACTIVOS_AVAL_INVERSIONES = 'Activos Aval Inversiones a Corto y Largo Plazo';
export const MEGA_NODE_ACTIVOS_AVAL_OTROS = 'Activos Aval Otros';
export const MEGA_NODE_PASIVOS_AVAL_ADEUDOS = 'Pasivos Aval Adeudos a corto plazo (creditos contratos, tarjetas bancarias, etc)';
export const MEGA_CREDIT_ACCEPTED = 'pre-aprobado';
export const EXECUTE_ACTION_82 = '6441c4ba66e10ea231d5071e';
export const OFFER_CS_FIELDS = [
  'montoprestamo',
  'plazocontrato',
];

export const OFFER_FACTORAJE_FIELDS = [
  'montoprestamo',
];
export const SIGNER_ROLES_TYPE = 'signer roles';
export const CUSTOM_FLOW_CONFIG_TYPE = 'custom flow configs';
export const BURO_FIELDS_BY_FLOW = 'Buro Fields By Flow';

export const LOGIN_PHONE_ID = '652d8c3f272bf2016d951954';
export const MATRIZ_EXECUTION_FIELDS_TYPE = '656a58b81f07e5a5c260aaac';

export const STEP_INFORMACION_DEL_CLIENTE = 'Información del Cliente';
export const STEP_STATUS_TERMINADO = 'Terminado';
export const NORMALIZED_STEP_INFORMACION_DEL_CLIENTE = 'informaciondelcliente';

export const TYPE_NAME_CHECK_HIDDEN_NODES_COMPLETED = 'checkModalHiddenNodesCompleted';
export const COMPANY_TYPE_FINISH_MODAL = '642b1f2d5124cb76a0470efc';
