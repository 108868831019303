import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    paddingTop: '8vh',
    paddingBottom: '5vh',
    overflowY: 'auto',
    height: 'calc(100% - 65px - 88px - 5px)',
    width: '100%',
    backgroundColor: '#F7FAFD',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
  header: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  title: {
    color: '#2C1482',
    maxWidth: '900px',
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  inputContainer: {
    margin: '1rem auto 2rem auto',
  },
  rfcTitle: {
    color: theme.palette.primary.main,
    maxWidth: '900px',
    margin: '0 auto',
    marginBottom: '1rem',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '2.5rem',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      fontSize: '1.5rem',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
  infoTitle: {
    color: theme.palette.secondary.main,
    maxWidth: '900px',
    margin: '20px auto 50px auto',
    fontWeight: 600,
    lineHeight: '1.25rem',
    fontSize: '1.125rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '1rem',
  },
  button: {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '1.2rem',
    padding: '12px 16px',
  },
}));
