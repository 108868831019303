import {
  CREDIT_DATA_OBJECT,
  CREDIT_KEYS_NUMBERS,
  DEFAULT_DECIMALES_REDONDEO,
  DEFAULT_PORCENTAJE_GASTOS_ESCRITURA,
  ENGANCHE_FIELD_ID,
  GASTOS_ESCRITURA_FIELD_ID,
  IMPORTE_FIELD_ID,
  PLAZO_FIELD_ID,
  PLAZO_YEARS_FIELD_ID,
  PROPIEDAD_VALOR_FIELD_ID,
  YEAR_KEY,
} from '../constants';
import { cleanMonth, convertirMonedaAEntero } from './commons';
import { convertirAMoneda, convertirANumero, redondearNumero } from './currency';
import { decryptData, encryptData } from './encryption';
import { indexFieldValuesByField } from './fieldValues';
import { calcAmmountGastosEscritura } from './simulator.utils';

const CREDIT_KEY = btoa('CREDIT_DATA');

export const storeCredit = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(CREDIT_KEY, encryptedData);
};

export const removeCredit = () => {
  window.localStorage.removeItem(CREDIT_KEY);
};

export const getCredit = () => {
  const jsonDataEncrypted = window.localStorage.getItem(CREDIT_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const saveExtraDataCredit = (extraData) => {
  const creditData = getCredit();
  storeCredit({
    ...creditData,
    ...extraData,
  });
};

const getItem = (key) => {
  try {
    const credit = getCredit();
    return credit[key];
  } catch {
    return undefined;
  }
};

export const getImporte = () => {
  const importe = getItem('importe');
  return importe;
};

export const getPlazo = () => {
  const importe = getItem('plazo');
  return importe;
};

export const getMeses = () => {
  const meses = getItem('meses');
  return meses;
};

export const getAforo = () => {
  const meses = getItem('aforo');
  return meses;
};

export const getEnganche = () => {
  const enganche = getItem('enganche');
  return enganche;
};

export const getYear = () => {
  const year = getItem('year');
  return year;
};

export const getMensualidad = () => {
  const mensualidad = getItem('mensualidad');
  return mensualidad;
};

export const getPropietyValue = () => {
  const propietyValue = getItem('propietyValue');
  return propietyValue;
};

export const getClientPropertyStage = () => {
  const clientPropertyStage = getItem('clientPropertyStage');
  return clientPropertyStage;
};

export const getClientHaveAgent = () => {
  const clientHaveAgent = getItem('clientHaveAgent');
  return clientHaveAgent;
};

export const getSaldoSubcuentaInfonavit = () => {
  const montoSubcuentaInfonavit = getItem('saldoSubcuentaInfonavit');
  return montoSubcuentaInfonavit;
};

export const getMensualInfonavit = () => {
  const montoMensualInfonavit = getItem('montoMensualInfonavit');
  return montoMensualInfonavit;
};

export const getReason = () => {
  const reason = getItem('reason');
  return reason;
};

export const getSueldoMensualBruto = () => {
  const sueldaMensualBruto = getItem('sueldoMensualBruto');
  return sueldaMensualBruto;
};

export const getMontoCreditoInfonavit = () => {
  const montoCreditoInfonavit = getItem('montoCreditoInfonavit');
  return montoCreditoInfonavit;
};

export const getTipoPrestamoInfonavit = () => {
  const infonavitLoanType = getItem('tipoPrestamoInfonavit');
  return infonavitLoanType;
};

export const getMontoGastosEscritura = () => {
  const gastosEscritura = getItem('montoGastosEscritura');
  return gastosEscritura || 0;
};

export const getIgresosNumber = () => {
  const igresos = getItem('ingresos');
  return igresos;
};

export const setConditonsAlreadyEditted = () => {
  saveExtraDataCredit({ conditionsEdditted: true });
};

export const getConditonsAlreadyEditted = () => {
  const conditionsEdditted = getItem('conditionsEdditted');
  return conditionsEdditted;
};

export const getCreditStoredData = () => {
  const importe = getImporte();
  const meses = getMeses();
  const aforo = getAforo();
  const propietyValue = getPropietyValue();
  const monthlyPaid = getMensualidad();
  const enganche = getEnganche();
  const year = getYear();
  const montoGastosEscritura = getMontoGastosEscritura();

  return {
    importe,
    meses,
    aforo,
    propietyValue,
    monthlyPaid,
    enganche,
    year,
    montoGastosEscritura,
  };
};

export const buildCreditObjectFromFieldValues = (fieldValues) => {
  const fieldValuesIndexed = indexFieldValuesByField(fieldValues);
  const creditDataObject = {};
  Object.entries(CREDIT_DATA_OBJECT).forEach(([key, value]) => {
    if (fieldValuesIndexed[value]) {
      const valueByKey = fieldValuesIndexed[value].value;
      const valueFound = YEAR_KEY ? valueByKey.split(' ')[0] : valueByKey;
      creditDataObject[key] = CREDIT_KEYS_NUMBERS.includes(key)
        ? convertirANumero(valueFound)
        : valueFound;
    }
  });
  return creditDataObject;
};

export const updateCreditDataByMatrizData = (newData) => {
  const {
    propiedadvalor, enganche, pagomensual, plazo,
  } = newData;
  const numberPropiedad = Number(convertirANumero(propiedadvalor).toFixed(2));
  const numberEnganche = Number(convertirANumero(enganche).toFixed(2));
  const numberPagoMensual = Number(convertirANumero(pagomensual).toFixed(2));
  const montoGastosEscritura = convertirANumero(
    (numberPropiedad * DEFAULT_PORCENTAJE_GASTOS_ESCRITURA).toFixed(2),
  );
  const importe = numberPropiedad - numberEnganche;
  const aforo = (importe / numberPropiedad).toFixed(4);
  const plazoMesesNumber = typeof plazo === 'string' ? cleanMonth(plazo) : plazo;
  const year = plazoMesesNumber / 12;
  const oldCreditData = getCredit();
  storeCredit({
    ...oldCreditData,
    importe,
    meses: plazoMesesNumber,
    aforo,
    enganche: numberEnganche,
    year,
    plazo: `${year} años`,
    mensualidad: numberPagoMensual,
    propietyValue: numberPropiedad,
    propietyValueCurrency: convertirAMoneda(numberPropiedad),
    montoGastosEscritura,
  });
};

export const getCreditObjectUnformatted = (creditData) => {
  const {
    propietyValue = 0,
    monthlyPaid = 0,
    importe = 0,
    meses = '',
    saldoSubcuentaInfonavit = 0,
    montoCreditoInfonavit = 0,
    sueldoMensualBruto = 0,
    montoMensualInfonavit = 0,
    aforo = '',
  } = creditData;
  const propietyValueNumber = convertirMonedaAEntero(propietyValue);
  const monthlyPaidNumber = convertirMonedaAEntero(monthlyPaid) || 0;
  const importeNumber = convertirMonedaAEntero(importe);
  const mesesNumber = cleanMonth(meses);
  const yearNumber = mesesNumber / 12;
  const year = `${yearNumber} años`;
  const engancheActual = Number((propietyValueNumber - importeNumber).toFixed(2));
  const gastosEscritura = calcAmmountGastosEscritura(propietyValueNumber);
  const montoCreditoInfonavitNumber = convertirMonedaAEntero(montoCreditoInfonavit);
  const sueldoMensualBrutoNumber = convertirMonedaAEntero(sueldoMensualBruto);
  const montoMensualInfonavitNumber = convertirMonedaAEntero(montoMensualInfonavit);
  const saldoSubcuentaInfonavitNumber = convertirMonedaAEntero(saldoSubcuentaInfonavit);

  return {
    importe: redondearNumero(importeNumber, DEFAULT_DECIMALES_REDONDEO),
    propietyValue: redondearNumero(propietyValueNumber, DEFAULT_DECIMALES_REDONDEO),
    monthlyPaid: monthlyPaidNumber,
    aforo,
    year,
    saldoSubcuentaInfonavit: saldoSubcuentaInfonavitNumber,
    montoCreditoInfonavit: montoCreditoInfonavitNumber,
    sueldoMensualBruto: sueldoMensualBrutoNumber,
    montoMensualInfonavit: montoMensualInfonavitNumber,
    enganche: redondearNumero(engancheActual, DEFAULT_DECIMALES_REDONDEO),
    gastosEscritura: redondearNumero(gastosEscritura, DEFAULT_DECIMALES_REDONDEO),
  };
};

export const getCreditValuesObject = (creditData) => {
  const {
    importe,
    propietyValue,
    monthlyPaid,
    aforo,
    year,
    enganche,
    gastosEscritura,
    saldoSubcuentaInfonavit,
    montoCreditoInfonavit,
    sueldoMensualBruto,
    montoMensualInfonavit,
  } = creditData;

  return {
    precio: propietyValue,
    enganche,
    gastosEscritura,
    year,
    prestamo: importe,
    monthlyPaid,
    aforo,
    montoCreditoInfonavit,
    saldoSubcuentaInfonavit,
    saldoSubcuentaFovissste: saldoSubcuentaInfonavit,
    sueldoMensualBruto,
    montoMensualInfonavit,
  };
};

export const fillValuesOnInitialValuesFromCreditObject = (
  valuesObj,
  creditObj,
) => Object.keys(valuesObj).reduce((acc, key) => {
  const baseValue = valuesObj[key];
  const newValue = creditObj[key];
  const isEmpty = newValue === undefined || newValue === null || newValue === '';

  return {
    ...acc,
    [key]: {
      ...baseValue,
      value: newValue || baseValue.value,
      isEmpty,
    },
  };
}, {});

export const fetchCreditDataFromFields = async ({ getFieldValueById = () => {} }) => {
  const [
    propietyValue,
    importe,
    enganche,
    plazo,
    meses,
    montoGastosEscritura,
  ] = await Promise.all([
    getFieldValueById(PROPIEDAD_VALOR_FIELD_ID),
    getFieldValueById(IMPORTE_FIELD_ID),
    getFieldValueById(ENGANCHE_FIELD_ID),
    getFieldValueById(PLAZO_YEARS_FIELD_ID),
    getFieldValueById(PLAZO_FIELD_ID),
    getFieldValueById(GASTOS_ESCRITURA_FIELD_ID),
  ]);

  const mesesValueClean = meses.split(' ')[0];

  return {
    propietyValue: convertirMonedaAEntero(propietyValue),
    importe: convertirMonedaAEntero(importe),
    enganche: convertirMonedaAEntero(enganche),
    plazo: cleanMonth(plazo),
    meses: mesesValueClean,
    montoGastosEscritura: convertirMonedaAEntero(montoGastosEscritura),
  };
};
