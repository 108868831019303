import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  mainContainer: {
    background: '#F7FAFD',
    height: 'calc(100% - 65px - 80px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4px',
    paddingBottom: '0px',
    // overflowY: 'hidden',
  },
  container: {
    maxWidth: '600px',
    margin: '0 auto',
  },
  titleContainer: {
    width: '100%',
    maxWidth: '600px',
    // overflowY: 'hidden',
    marginBottom: '24px',
  },
  iframe: {
    height: '100%',
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '1rem',
  },
  buttonLink: {
    background: 'none',
    border: 'none',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textTransform: 'none',
    textDecoration: 'underline',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
    fontSize: 'inherit',
    fontWeight: 'bold',
    lineHeight: 'inherit',
  },
  progressContainer: {
    width: '100%',
    maxWidth: '600px',
  },
  form: {
    background: '#F7FAFD',
    width: '100%',
    padding: '2vh 0 2vh 0',
    overflowY: 'auto',
    backgroundColor: '#F7FAFD',
    height: 'calc(100% - 65px - 88px - 5px)',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 65px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 96.8px - 57px - 5px)',
    },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'normal',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  scrollContainer: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
