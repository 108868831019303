/* eslint-disable no-unused-vars */
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from '../components/ProtectedRoute';
import { Loader } from '../components';

import { AUTH_ROUTE_TYPE, ONLY_CLIENTS_ROUTE_TYPE } from '../constants/routeTypes';
import { CotizationPage } from '../containers/main/pages/CotizacionPage';
import CreateAccountPage from '../containers/main/pages/CreateAccountPage';
import { MainLayout } from '../containers/main/layouts';
import MainRoutes from '../containers/main/router/MainRoutes';

import { CheckTokenUrl } from '../middlewares/CheckTokenUrl';
import ChiploRoutes from '../containers/Chiplo/router/ChiploRoutes';
import AgentRoutes from '../containers/agente/router/AgentRoutes';
import { AGENTE_LOGIN_ROUTE, LOGIN_ROUTE } from '../constants';
import { AuthLayout } from '../containers/auth/layouts/AuthLayout';
import { Login } from '../containers/auth/pages/Login';
import { useProduct } from '../hooks/useProduct';

import InfonavitRoutes from '../containers/Infonavit/router/InfonavitRoutes';
import LiquidezRoutes from '../containers/Liquidez/router/LiquidezRoutes';
import ViviendaRoutes from '../containers/Vivienda/router/ViviendaRoutes';
import { CreateAccountProvider } from '../context/createAccount';
import EntityRoutes from '../containers/entity/router/EntityRoutes';
import { WallOfLovePage } from '../containers/WallOfLove';

export const AppRouter = () => {
  const { products } = useProduct();
  const defaultProduct = products[0];
  return (
    <Routes>
      <Route
        path="/cotizacion"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/account"
            additionalData={{ authProtected: false }}
          >
            <MainLayout>
              <CotizationPage />
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/account"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main/consult"
            additionalData={{ authProtected: false }}
          >
            <MainLayout>
              <CreateAccountProvider>
                <CreateAccountPage />
              </CreateAccountProvider>
            </MainLayout>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/main/*"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath={LOGIN_ROUTE}
            additionalData={{ authProtected: true, path: '/main/*' }}
          >

            <CheckTokenUrl>
              <ProtectedRoute
                type={ONLY_CLIENTS_ROUTE_TYPE}
                redirectPath={AGENTE_LOGIN_ROUTE}
                additionalData={{ authProtected: true }}
              >
                <Suspense fallback={<Loader />}>
                  <MainRoutes />
                </Suspense>
              </ProtectedRoute>
            </CheckTokenUrl>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/vivienda/*"
        element={(
          <CheckTokenUrl>
            <ViviendaRoutes />
          </CheckTokenUrl>
        )}
      />
      <Route
        path="/liquidez/*"
        element={(
          <CheckTokenUrl>
            <LiquidezRoutes />
          </CheckTokenUrl>
        )}
      />
      <Route
        path="/login"
        element={(
          <ProtectedRoute
            type={AUTH_ROUTE_TYPE}
            redirectPath="/main"
            additionalData={{ authProtected: false }}
          >
            <Suspense fallback={<Loader />}>
              <AuthLayout product={defaultProduct}><Login /></AuthLayout>
            </Suspense>
          </ProtectedRoute>
        )}
      />
      <Route
        path="/agente/*"
        element={<AgentRoutes />}
      />
      <Route
        path="/inmobiliaria/*"
        element={<EntityRoutes />}
      />
      <Route
        path="/infonavit/*"
        element={<InfonavitRoutes />}
      />
      <Route
        path="/wall-of-love/*"
        element={(
          <MainLayout
            showStepperBar={false}
            displayAuthUser={false}
            navigateOnLogoClick
          >
            <WallOfLovePage />
          </MainLayout>
        )}
      />
      <Route
        path="/*"
        element={(
          <Suspense fallback={<Loader />}>
            <ChiploRoutes />
          </Suspense>
        )}
      />
    </Routes>
  );
};
