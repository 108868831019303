/* eslint-disable no-mixed-operators */

import { INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL } from '../constants';

/* eslint-disable max-len */
export const convertirAMoneda = (numero, incluirDecimales = true) => {
  if (typeof numero === 'string') {
    return numero; // Si ya es una cadena, la devolvemos tal cual
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: incluirDecimales ? 2 : 0,
    maximumFractionDigits: incluirDecimales ? 2 : 0,
  });
  return formatter.format(numero);
};

export const formatMoney = (value) => (
  `$${value.replace(/\d(?=(\d{3})+(\.\d+)?$)/g, '$&,')}`
);

export const formatToNumber = (data) => data.replace(/[,$]/g, '');

export const convertirANumero = (monto) => {
  const numero = parseFloat(monto.replace(/[^0-9.-]+/g, ''));
  return numero || 0;
};

export const redondearNumero = (numero, decimales = 0) => {
  if (typeof numero === 'string' || !numero) return 0;
  const factor = 10 ** decimales;
  return Math.round(numero * factor) / factor;
};

export const contieneNumeros = (cadena) => {
  // Expresión regular para buscar dígitos numéricos
  const regex = /\d/;
  return !!regex.test(cadena);
};

export const getMonthlyPaid = ({ importe, plazoMensual, tasa }) => importe * tasa / 12 * (1 + tasa / 12) ** plazoMensual / ((1 + tasa / 12) ** plazoMensual - 1);

export const getPrestamoBancario = (mensualidad, tasa, plazoMensual) => {
  const importe = (mensualidad * (((1 + tasa / 12) ** plazoMensual) - 1))
  / (tasa / 12 * (1 + tasa / 12) ** plazoMensual);
  return importe;
};

export const getViviendaEnganche = ({
  ingresos,
  RPI = 0.5,
  plazo = 240,
  tasa = 0.107685,
  porcentajeEnganche = INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL,
}) => {
  const mensualidad = (ingresos * RPI);
  const prestamoBancario = getPrestamoBancario(mensualidad, tasa, plazo);
  const valorVivienda = prestamoBancario / (1 - porcentajeEnganche);
  const enganche = valorVivienda * porcentajeEnganche;
  return { valorVivienda, enganche };
};

export const getIngresosRecomendados = ({ pagoMensual, porcentajeEndeudamiento = 0.5 }) => {
  const ingresosRecomendados = pagoMensual / porcentajeEndeudamiento;
  return ingresosRecomendados;
};

export const eliminarCentavos = (value) => {
  if (typeof value === 'string') {
    // should delete end .00 of the final value
    return value.replace(/\.00$/, '');
  }
  return value;
};
