/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import moment from 'moment';
import { normalizeName } from 'normalize-text';
import { convertDateToFormat, sortByDateDescending } from '../../../utils/date';
import * as userAPI from '../../../api/users';
import { cleanPhonePrefix } from '../../../utils/clients';

const getTitle = (key) => {
  const fecha = moment(key, 'YYYY-MM-DD').format('YYYY-MMMM-D');
  const [year, month] = fecha.split('-');
  return `${month} ${year}`;
};

const getUserByEmail = async (email) => {
  const user = await userAPI.getUserByEmail(email);
  return user;
};

const setTagsFromProcess = (tags) => {
  const formattedTags = Object.keys(tags)
    .filter((keyTag) => tags[keyTag].show)
    .sort((a, b) => tags[a].position - tags[b].position)
    .map((keyTag) => ({
      name: keyTag,
      label: tags[keyTag].label,
      completed: tags[keyTag].completed,
      extraInfo: tags[keyTag].sort.map((field) => tags[keyTag][field] || '').filter((value) => value),
      ...(keyTag === 'perfilamientocrediticio' ? { banco: tags[keyTag]?.banco } : {}),
    }));
  return formattedTags;
};

const mergeTags = (tagsToMerge, oldTags, nextTags) => Object.keys(nextTags)
  .map((keyTag) => (tagsToMerge.includes(keyTag) ? { [keyTag]: oldTags[keyTag] } : { [keyTag]: nextTags[keyTag] }))
  .reduce((acc, curr) => ({ ...acc, ...curr }), {});

const transformProcessDataTags = (processesData) => {
  if (processesData.length === 1) {
    const newProcessData = processesData.map((processData) => {
      const transformedTags = setTagsFromProcess(processData.tags);
      return { ...processData, tags: transformedTags };
    });
    return newProcessData;
  }
  const creditoHipotecarioProcess = processesData.find(({
    flow,
  }) => normalizeName(flow) === normalizeName('Crédito hipotecario'));
  const newProcessData = processesData
    .filter(({ _id }) => _id !== creditoHipotecarioProcess._id)
    .map((processData) => {
      const mergedTags = mergeTags(['iniciatramite', 'consultaburo'], creditoHipotecarioProcess.tags, processData.tags);
      const transformedTags = setTagsFromProcess(mergedTags);
      return { ...processData, tags: transformedTags };
    });
  return newProcessData;
};

const getStatusTag = (tags) => {
  const index = tags.filter(({ completed }) => completed).length;
  const label = tags.length === index ? 'Terminado' : tags[index].label;
  return label;
};

const getBancoAndStatusFromTags = (tags) => {
  const tagBanco = tags.find((tag) => tag.name === 'perfilamientocrediticio') || {};
  const label = getStatusTag(tags);
  const banco = tagBanco?.banco ? tagBanco.banco.split(' ').reverse()[0] : 'No definido';
  return { banco, status: label };
};

export const convertStructure = async (clientsData) => {
  const newClientsData = await Promise.all(clientsData.map(async (clientData) => {
    const { phone } = await getUserByEmail(clientData.email);
    const newProcessData = transformProcessDataTags(clientData.processesData);
    const nameUppercase = clientData?.name?.toUpperCase();
    const processesData = newProcessData.map((processData) => {
      const { flow } = processData;
      const dateProcessdata = convertDateToFormat(processData.createdAt, 'D [de] MMMM YYYY');
      const { banco, status } = getBancoAndStatusFromTags(processData.tags);
      return {
        ...processData,
        name: flow,
        date: dateProcessdata,
        banco,
        status,
      };
    });
    return {
      ...clientData,
      name: nameUppercase,
      phone: cleanPhonePrefix(phone),
      processesData,
    };
  }));

  const clientsSortedByDate = sortByDateDescending(newClientsData);
  const clientsFormattedDate = clientsSortedByDate.map((client) => ({
    ...client,
    date: convertDateToFormat(client.createdAt, 'D [de] MMMM YYYY'),
  }));
  return clientsFormattedDate;
};

const groupData = (clientsData) => {
  const groupedData = clientsData.reduce((result, item) => {
    const formatedDate = convertDateToFormat(item.createdAt, 'YYYY-MM-DD'); // 2023-09-01
    const [year, month] = formatedDate.split('-');
    const date = `${year}-${month}-01`;
    if (!result[date]) {
      result[date] = [];
    }
    result[date].push(item);
    return result;
  }, {});
  return groupedData;
};

export const getGroupedData = (clientsData) => {
  if (!clientsData.length) return clientsData;
  const groupedData = groupData(clientsData);
  const sortedGroup = Object.keys(groupedData)
    .map((key) => ({
      sort: key,
      title: getTitle(key),
      clients: groupedData[key],
    }))
    .sort((a, b) => {
      if (a.sort > b.sort) return -1;
      if (a.sort < b.sort) return 1;
      return 0;
    });
  const newSrotedGroup = sortedGroup.map((group, indexGrop) => {
    const newClients = group.clients.map((client, index) => ({ ...client, lastNode: (indexGrop + 1 === sortedGroup.length) && (index + 1 === group.clients.length) }));
    return { ...group, clients: newClients };
  });
  return newSrotedGroup;
};
