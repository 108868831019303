import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  formContainer: {
    height: 'initial',
    overflowY: 'hidden',
    [theme.breakpoints.down('md')]: {
      height: 'initial',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'initial',
    },
  },
  footerText: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '1.75rem',
    color: '#2C1482',
    maxWidth: '650px',
    margin: '0px auto 1rem auto',
    padding: '0px 16px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px',
      padding: '0px',
      margin: '2rem auto',
    },
  },
  buttonFooterTitle: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    color: '#6D6D6D',
    padding: '10px 0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      lineHeight: '17px',
    },
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '25px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '50px',
    },
  },
  buttonInmobiliariaContainer: {
    textAlign: 'center',
    marginTop: '0px',
    whiteSpace: 'nowrap',
    maxWidth: '300px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '395px',
      marginTop: '0px',
    },
  },
  button: {
    width: '100%',
    backgroundColor: '#FFA500',
    borderRadius: '5px',
    textTransform: 'none',
    fontWeight: 700,
    color: '#000',
    fontFamily: '"Montserrat", "sans-serif"',
    fontSize: '1.125rem',
    lineHeight: '1.75rem',
    padding: '16px 20px',
    '&:hover': {
      background: '#FFA500',
      opacity: '0.6',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.625rem',
      padding: '16px 60px',
    },
  },
  container: {
    backgroundColor: '#FFFFFF',
    height: 'calc(100% - 145px - 90px - 5px)',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100% - 75px - 88px - 5px)',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - 55px - 88px - 5px)',
    },
  },
  inmobiliariaContainer: {
    backgroundColor: '#FFFFFF',
    height: 'calc(100% - 65px)',
    paddingTop: theme.spacing(1),
  },
  containerMultiOptions: {
    height: 'calc(100% - 5px - 88px - 5px)',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 5px - 85.19px - 5px)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100% - 5px - 57px - 5px)',
    },
  },
  header: {
    maxWidth: '900px !important',
  },
  whatss: {
    right: '8%',
    bottom: '40px',
    [theme.breakpoints.down('xs')]: {
      bottom: '60px',
      right: '4%',
      width: '40px',
      height: '40px',
    },
  },
  FactorajeDataContainer: {
    margin: '1rem auto 1rem auto',
    maxWidth: '600px',
    padding: '0 15px 0 15px',
    [theme.breakpoints.up('sm')]: {
      padding: '0',
    },
  },
  whats: {
    textAlign: 'center',
    display: 'block',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    marginTop: '40px',
    textDecoration: 'none',
    [theme.breakpoints.up('sm')]: {
      marginTop: '120px',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      padding: '0 0px',
    },
  },
  messageWhats: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
    color: '#2C1482',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    marginTop: '30px',
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '1.75rem',
      padding: '0 0px',
      marginTop: '45px',
      marginBottom: '25px',
    },
  },
  componentTitle: {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '15px',
    lineHeight: '1.25rem',
    color: '#176BCD',
    fontFamily: 'Montserrat, sans-serif',
    padding: '0 16px',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '120px',
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      padding: '0 0px',
    },
  },
  buttonSimulator: {
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '1.25rem',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2DBDB',
    borderRadius: '5px',
    color: '#444345',
    fontFamily: 'Montserrat, sans-serif',
    padding: '6px 16px',
    marginTop: '40px',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '2.2rem',
      padding: '0 20px',
    },
  },
  buttonMultiOptions: {
    cursor: 'pointer',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '1.7rem',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E2DBDB',
    borderRadius: '5px',
    color: '#444345',
    fontFamily: 'Montserrat, sans-serif',
    padding: '10px 16px',
    margin: '10px 0',
    flex: '1',
    width: '100%',
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.20rem',
      lineHeight: '2.5rem',
      padding: '0 20px',
    },
  },
  buttonExtraOptions: {
    fontSize: '0.875rem',
    textTransform: 'none',
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
    textAlign: 'center',
    lineHeight: '1.2rem',
    color: '#2C1482',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    border: 'none',
    paddingTop: '20px',
    cursor: 'pointer',
    '&:hover': {
      color: '#2C1482',
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: '#2C1482',
      backgroundColor: 'transparent',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.0625rem',
      lineHeight: '2rem',
    },
  },
  containerExtraOptions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    textAlign: 'center',
  },
  containerBanks: {
    maxWidth: '850px',
    width: '100%',
    margin: '0 auto',
    marginTop: '50px',
  },
  title: {
    color: '#2C1482',
    maxWidth: '850px',
    margin: '0 auto',
    fontWeight: 700,
    fontSize: '2.2rem',
    lineHeight: '2.8rem',
    textAlign: 'left',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.5rem',
      lineHeight: '3rem',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '3rem',
      lineHeight: '3.55rem',
      textAlign: 'center',
    },
  },
  subtContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '20px',
    paddingTop: '35px',
  },
  subtitle: {
    fontSize: '18px',
    lineHeight: '1.25rem',
    fontFamily: '"Montserrat", "sans-serif"',
    color: '#176BCD',
    fontWeight: '600',
    textAlign: 'center',
    maxWidth: '730px',
  },
  extraInputClassName: {
    borderColor: 'gray',
  },
  containerCheckSendEmail: {
    padding: 'unset !important',
    marginBottom: '0 !important',
  },
  labelCheckSendEmail: {
    fontFamily: 'Open Sans !important',
    fontWeight: '600',
    fontSize: '0.775rem !important',
    lineHeight: '1.6rem !important',
    display: 'flex',
    alignItems: 'center',
    textDecorationLine: 'underline',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem !important',
      lineHeight: '2.3rem !important',
      textAlign: 'center',
    },
  },
}));
