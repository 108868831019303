import { getAllCompanyConfigs } from '../api/companyConfigs';
import { COMPANY, CUSTOM_FLOW_CONFIG_TYPE } from '../constants';

export const normalizeText = (text) => text
  .normalize('NFD')
  .replace(/\p{Diacritic}/gu, '')
  .toLowerCase()
  .replace(/ /g, '');

export const findFields = (fieldsNames, sections) => sections.reduce((acc, curr) => {
  const { fields } = curr;
  const filterValues = fields.filter(({ name }) => fieldsNames.includes(name));
  return [...acc, ...filterValues];
}, []);

export const convertirMonedaAEntero = (moneda) => {
  if (Number.isInteger(moneda)) return moneda;
  // Usamos una expresión regular para eliminar caracteres no numéricos
  const soloNumeros = moneda.replace(/[^0-9.]/g, '');
  // Convertimos la cadena resultante en un número entero
  const entero = parseFloat(soloNumeros);
  return entero;
};

export const cleanMonth = (rawMonth) => parseInt(rawMonth.split(' ')[0], 10);

export const getConsultPageConfigByFlow = async (normalizedFlowName, consultPageName, pageName) => {
  const companyConfigs = await getAllCompanyConfigs(COMPANY);
  const { payload } = companyConfigs.find(({ type: { name = '' } = {} }) => name === CUSTOM_FLOW_CONFIG_TYPE);
  const allPagesConfig = payload[normalizedFlowName][pageName];
  const pageConfig = allPagesConfig[consultPageName];
  return pageConfig;
};
