import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  setPerfiladorInputValue,
  setPerfiladorResultado,
  selectInputValue,
  selectInputNumber,
  selectFormattedResultado,
} from '../store/perfilador/perfiladorSlice';
import {
  convertirAMoneda,
  getIngresosRecomendados,
  getViviendaEnganche,
  redondearNumero,
} from '../utils/currency';
import { getMonthlyPaid, storeIngresosData, storePerfiladorValuePropiety } from '../utils';
import {
  getPerfiladorClientIngresos,
  getPerfiladorClientValuePropNumber,
} from '../storage';
import { calcAmmountGastosEscritura } from '../utils/simulator.utils';

import {
  CALC_MODE_INGREOS_MENSUALES,
  CALC_MODE_PRECIO_INMUEBLE,
  DEFAULT_DECIMALES_REDONDEO,
  DEFAULT_PLAZO_20,
  DEFAULT_PORCENTAJE_ENDEUDAMIENTO,
  DEFAULT_RPI,
  DEFAULT_TASA_ANUAL_20_YEARS,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL,
} from '../constants';

const usePerfilador = ({ calcMode, useDesembolsoMode = false }) => {
  const dispatch = useDispatch();
  const inputValue = useSelector(selectInputValue);
  const inputNumber = useSelector(selectInputNumber);
  const resultado = useSelector(selectFormattedResultado);

  const calculoMensualidad = useCallback((importe) => {
    const tasaAnual = DEFAULT_TASA_ANUAL_20_YEARS * 0.01;
    const plazoMensual = DEFAULT_PLAZO_20 * 12;
    const result = getMonthlyPaid({ importe, plazoMensual, tasa: tasaAnual });
    return Number(result.toFixed(2));
  }, []);

  const calculoMontoCredito = useCallback((valor) => {
    const enganche = valor * INPUT_SIMULATOR_MINIMUN_ENGANCHE_DECIMAL;
    const importe = valor - enganche;
    return Number(importe.toFixed(2));
  }, []);

  const calculoIngresosRecomendados = useCallback((pagoMensual) => {
    const result = getIngresosRecomendados({
      pagoMensual,
      porcentajeEndeudamiento: DEFAULT_PORCENTAJE_ENDEUDAMIENTO,
    });
    return Number(result.toFixed(2));
  }, []);

  const calcularPrecioInmueble = useCallback(() => {
    const propietyValue = inputNumber;

    const montoCredito = calculoMontoCredito(propietyValue);
    const pagoMensual = calculoMensualidad(montoCredito);
    const ingresosRecomendados = calculoIngresosRecomendados(pagoMensual);
    const montoGastosEscritura = useDesembolsoMode
      ? calcAmmountGastosEscritura(propietyValue)
      : 0;
    return {
      propietyValue: redondearNumero(propietyValue, DEFAULT_DECIMALES_REDONDEO),
      montoCredito: redondearNumero(montoCredito, DEFAULT_DECIMALES_REDONDEO),
      pagoMensual: redondearNumero(pagoMensual, DEFAULT_DECIMALES_REDONDEO),
      ingresosRecomendados: redondearNumero(
        ingresosRecomendados,
        DEFAULT_DECIMALES_REDONDEO,
      ),
      montoGastosEscritura: redondearNumero(
        montoGastosEscritura,
        DEFAULT_DECIMALES_REDONDEO,
      ),
    };
  }, [
    inputNumber,
    calculoMontoCredito,
    calculoMensualidad,
    calculoIngresosRecomendados,
    useDesembolsoMode,
  ]);

  const calcularIngresosMensuales = useCallback(() => {
    const { valorVivienda } = getViviendaEnganche({
      ingresos: inputNumber,
      tasa: DEFAULT_TASA_ANUAL_20_YEARS * 0.01,
      RPI: DEFAULT_RPI,
    });

    const propietyValue = Number(valorVivienda.toFixed(2));
    const montoCredito = calculoMontoCredito(propietyValue);
    const pagoMensual = calculoMensualidad(montoCredito);
    const montoGastosEscritura = useDesembolsoMode ? calcAmmountGastosEscritura(propietyValue) : 0;
    return {
      propietyValue: redondearNumero(propietyValue, DEFAULT_DECIMALES_REDONDEO),
      montoCredito: redondearNumero(montoCredito, DEFAULT_DECIMALES_REDONDEO),
      pagoMensual: redondearNumero(pagoMensual, DEFAULT_DECIMALES_REDONDEO),
      montoGastosEscritura: redondearNumero(montoGastosEscritura, DEFAULT_DECIMALES_REDONDEO),
    };
  }, [inputNumber, calculoMontoCredito, calculoMensualidad, useDesembolsoMode]);

  useEffect(() => {
    let resultadoCalculo;
    if (calcMode === CALC_MODE_PRECIO_INMUEBLE) {
      resultadoCalculo = calcularPrecioInmueble();
    } else if (calcMode === CALC_MODE_INGREOS_MENSUALES) {
      resultadoCalculo = calcularIngresosMensuales();
    } else {
      throw new Error('calcMode no válido');
    }
    dispatch(setPerfiladorResultado(resultadoCalculo));
  }, [inputValue, calcularPrecioInmueble, calcularIngresosMensuales, dispatch]);

  const getAndSetIngresosCliente = useCallback(() => {
    const localIngresos = getPerfiladorClientIngresos();
    if (localIngresos) {
      const ingresosCurrency = convertirAMoneda(localIngresos);
      dispatch(setPerfiladorInputValue(ingresosCurrency));
      return;
    }
    dispatch(setPerfiladorInputValue(''));
  }, [dispatch]);

  const getAndSetPropietyValue = useCallback(() => {
    const localClientPropNumber = getPerfiladorClientValuePropNumber();
    if (localClientPropNumber) {
      const ingresosCurrency = convertirAMoneda(localClientPropNumber, false);
      dispatch(setPerfiladorInputValue(ingresosCurrency));
      return;
    }
    dispatch(setPerfiladorInputValue(''));
  }, [dispatch]);

  const onChange = useCallback((event, value) => {
    dispatch(setPerfiladorInputValue(value));
    switch (calcMode) {
      case CALC_MODE_INGREOS_MENSUALES:
        storeIngresosData(value);
        break;
      case CALC_MODE_PRECIO_INMUEBLE:
        storePerfiladorValuePropiety(value);
        break;
      default:
        break;
    }
  }, [dispatch, calcMode, storeIngresosData, storePerfiladorValuePropiety]);

  useEffect(() => {
    switch (calcMode) {
      case CALC_MODE_INGREOS_MENSUALES:
        return getAndSetIngresosCliente();
      case CALC_MODE_PRECIO_INMUEBLE:
        return getAndSetPropietyValue();
      default:
        break;
    }
    return null;
  }, [calcMode, getAndSetIngresosCliente, getAndSetPropietyValue]);

  return {
    inputValue,
    inputNumber,
    onChange,
    ...resultado,
  };
};

export default usePerfilador;
