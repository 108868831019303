import React from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import infoStepsJson from '../../../../data/Landing.json';
import useStyles from './useStyles';
import { WhatsappButton } from '../../../../components/WhatsappButton';
import { FAQ_ROUTE } from '../../../../constants';
import { ButtonCustom } from '../../../../components';

export const FooterInfoImages = () => {
  const {
    FooterImages: {
      title,
      body,
      buttonTitle,
      faqButtonTitle,
    },
  } = infoStepsJson;
  const classes = useStyles();

  const navigate = useNavigate();

  const handleFAQClick = () => {
    navigate(FAQ_ROUTE);
  };

  return (
    <Box component="section" className={classes.container}>
      <Box className={classes.innerContainer}>
        <Box
          className={clsx(classes.item, classes.itemBackground)}
        >
          <Box className={clsx(
            classes.innerItem,
            classes.marginContainer,
            classes.displayFlexbox,
          )}
          >
            <Typography variant="h2" component="h2" className={classes.title}>{title}</Typography>
            <Typography variant="body1" component="p" className={classes.body}>{body}</Typography>
            <Box className={classes.containerButton}>
              <ButtonCustom
                onClick={handleFAQClick}
              >
                {faqButtonTitle}
              </ButtonCustom>
              <WhatsappButton>{buttonTitle}</WhatsappButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
