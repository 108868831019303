/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { convertirANumero } from '../../utils/currency';
import { storePerfiladorValuePropiety } from '../../utils';

const initialState = {
  inputValue: '',
  inputNumber: 0,
  resultado: {},
};

export const perfiladorSlice = createSlice({
  name: 'perfilador',
  initialState,
  reducers: {
    setPerfiladorInputValue: (state, action) => {
      state.inputValue = action.payload;
      state.inputNumber = convertirANumero(action.payload);
    },
    setPerfiladorResultado: (state, action) => {
      state.resultado = action.payload;
    },
    setInitialPerfiladorPrecioInmueble: (state, action) => {
      state.inputValue = action.payload;
      state.inputNumber = convertirANumero(action.payload);
      storePerfiladorValuePropiety(action.payload);
    },
  },
});

export const {
  setPerfiladorInputValue,
  setPerfiladorResultado,
  setInitialPerfiladorPrecioInmueble,
} = perfiladorSlice.actions;

// Selectores
export const selectInputValue = (state) => state.perfilador.inputValue;
export const selectInputNumber = (state) => state.perfilador.inputNumber;
export const selectResultado = (state) => state.perfilador.resultado;

// Selector memoizado
export const selectFormattedResultado = createSelector(
  [selectResultado],
  (resultado) => ({
    ...resultado,
    formattedIngresosRecomendados: new Intl.NumberFormat('es-MX').format(resultado.ingresosRecomendados),
  }),
);

export default perfiladorSlice.reducer;
