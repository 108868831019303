export const isGreaterThanValue = (inputValue, limitValue, errorMessage) => {
  const isValid = inputValue > limitValue;
  return { isValid, errorMessage };
};

export const isValidPercentage = (iValue, precio, limitValue, errorMessage) => {
  const isValid = parseInt(((iValue / precio) * 100).toFixed(0), 10) >= limitValue;
  return { isValid, errorMessage };
};

export const calcAmmountGastosEscritura = (valorPropiedad, porcentajeGastosEscritura = 5) => {
  const gastosEscritura = (porcentajeGastosEscritura / 100) * valorPropiedad;
  return gastosEscritura;
};

export const calculateEngancheFromDesembolso = (
  valorPropiedad,
  valorDesembolso,
  porcentajeGastosEscritura = 5,
) => {
  const resultGastosEscrituracion = calcAmmountGastosEscritura(
    valorPropiedad,
    porcentajeGastosEscritura,
  );
  const enganche = valorDesembolso - resultGastosEscrituracion;
  return {
    enganche,
    gastosEscritura: resultGastosEscrituracion,
  };
};
