// !Remove
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { Container, Button, Typography } from '@material-ui/core';
import * as googleAnalytics from '../../../../../utils/googleAnalytics/events';
import { FormLayout } from '../../../layouts/FormLayout';
import { AlertDialog, GroupFields, Loader } from '../../../../../components';
import * as fieldValueAPI from '../../../../../api/fieldValue';

import useLoading from '../../../../../hooks/useLoading';
import useError from '../../../../../hooks/useError';

import {
  AuthError, createUpdateTagGeneral, getAuth, storeAuth, storeStepData, getCurrentStep,
} from '../../../../../utils';

import * as clientsAPI from '../../../../../api/clients';
import * as processAPI from '../../../../../api/process';
import * as productAPI from '../../../../../api/product';
import * as userAPI from '../../../../../api/users';
import * as nodeAPI from '../../../../../api/node';
import * as leadsAPI from '../../../../../api/leads';

import useStyles from './useStyles';
import {
  COMPANY, CREATE_ACCOUNT_NAME,
  LOGIN_PHONE_ID, LOGIN_ROUTE, REJECTED, FINISH_TYPE_MATRIZ_VIVIENDA,
  PERFILAMIENTO_EXIST, PROFILING_COTIZATION, PROFILING_EMPTY_MATRIZ,
  FLOW_VIVIENDA,
  CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES,
  DEFAULT_TASAS,
  MONTHLY_OPTIONS,
  DEFAULT_TASA,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
} from '../../../../../constants';
import {
  createTags, getActualStep, getConfigTags, getFlowTypeHandler, updateCurrentPageOnLocalStep, updateLocalStepsByStep,
} from '../../../../../utils/tags';
import { StepperContext } from '../../../../../context/stepper';
import { AuthContext } from '../../../../../context/auth/authContext';
import { useProduct } from '../../../../../hooks/useProduct';
import { storeProductData } from '../../../../../utils/product';
import { convertirAMoneda, convertirANumero, getMonthlyPaid } from '../../../../../utils/currency';
import { generateSharedFieldValues } from '../../../../../utils/clients';
import {
  saveSharedFieldValuesData,
} from '../../../../../api/sharedFieldValues';
import { SendCodeDialog } from '../../../../../components/SendCodeDialog';
import { SimpleInputDialog } from '../../../../../components/SimpleInputDialog';
import { TermsAndConditions } from '../../../../../components/TermsAndConditions';
import { InformationDialog } from '../../../../../components/InformationDialog';
import { nowDate } from '../../../../../components/Input/InputBaseDatePicker/formatter';
import { getConfigs } from '../../../../../utils/configs';
import { useMatrizProfilling } from '../../../hooks/useMatrizProfilling';
import { getFlowName } from '../../../../../utils/flows';

import { createLeadFromClientData, storeClientFieldsData } from '../../../shared/CreateClientAccount';
import { getInitialDataInClientsByLink } from '../../../../../api/chiplo';
import { InputSimulator } from '../../../../../components/InputSimulator';
import { calcAmmountGastosEscritura } from '../../../../../utils/simulator.utils';
import { isValueAValidCharacter } from '../../../../../utils/fields';

const NORMAL_FINISH_TYPE = 'NORMAL';

const PHONE = 'phone';
const EMAIL = 'email';
const FIRSTNAME = 'firstName';
const LASTNAME = 'lastName';
const SECOND_NAME = 'secondName';
const SECOND_LASTNAME = 'secondLastName';
const FULL_NAME_AGENTE = 'fullNameAgente';

const INITIAL_ERROR = {
  isError: false,
  errorMessage: 'La verificacionestamal',
};
const INITIAL_SIMPLE_INPUT_DIALOG = {
  show: false,
  input: {},
  buttonTitle: 'Actualizar Número',
  title: 'Ingresa tu número de teléfono',
  error: {
    isError: false,
    errorMessage: '',
  },
};

export const CreateClientByLink = () => {
  const [valuesInput, setValuesInput] = useState(CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES);
  const tasas = DEFAULT_TASAS;
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [phoneValue, setPhoneValue] = useState('');
  const [isErrorPhoneValue, setIsErrorPhoneValue] = useState(INITIAL_ERROR);
  const [showSendCodeDialog, setShowSendCodeDialog] = useState(false);
  const [simpleInputDialogData, setSimpleInputDialogData] = useState(INITIAL_SIMPLE_INPUT_DIALOG);
  const { isLoading, startLoading, endLoading } = useLoading();
  const { getDefaultProduct } = useProduct();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { pathname } = location;
  const isVivienda = useMemo(() => pathname === `${FLOW_VIVIENDA}/account`, [pathname]);
  const {
    getCatTasaFieldValues,
    getMatrizBody,
    execMatrizProfilling,
    formatPerfilamientoFieldValue,
    updateClientConditionsValues,
  } = useMatrizProfilling({ company: COMPANY });
  const {
    showError,
  } = useError();

  const defaultProduct = getDefaultProduct();

  const { initTimeoutToken } = useContext(AuthContext);
  const {
    initStepper,
    updateStateStepper, state: { steps, current },
  } = useContext(StepperContext);

  const navigate = useNavigate();
  const [idRoles, setIdRoles] = useState(['']);

  const [showModal, setShowModal] = useState(false);
  const [sections, setSections] = useState([]);
  const [agentId, setAgentId] = useState(null);
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const [showProcessAlreadyCreatedDialog, setShowProcessAlreadyCreatedDialog] = useState(false);
  const [pageConfig, setPageConfig] = useState({
    title: '',
    agentSectionTitle: '',
    finishType: NORMAL_FINISH_TYPE,
    profillingFields: [],
  });
  const [productId, setProductId] = useState(defaultProduct._id);
  const [flow, setFlow] = useState([]);

  const propietyValue = useMemo(() => valuesInput.precio.value, [valuesInput]);

  const engancheValue = useMemo(() => {
    const enganche = valuesInput.enganche.value;
    return enganche;
  }, [propietyValue]);

  const gastosEscrituraValue = useMemo(() => {
    const gastosEscritura = calcAmmountGastosEscritura(propietyValue);
    return gastosEscritura;
  }, [propietyValue]);

  const prestamoValue = useMemo(() => propietyValue - engancheValue, [engancheValue, propietyValue]);

  const monthlyPay = useMemo(() => {
    const { id } = MONTHLY_OPTIONS.find(({ value }) => value === valuesInput.year.value);
    if (!valuesInput.precio.value || !tasas) return 0;
    const tasaCurrent = (tasas[id] * 0.01) || DEFAULT_TASA;
    const paid = getMonthlyPaid({
      importe: prestamoValue,
      plazoMensual: id * 12,
      tasa: tasaCurrent,
    });
    return +paid.toFixed(2);
  }, [valuesInput, prestamoValue, tasas]);

  const classes = useStyles();

  const handleChange = (event, value, position) => {
    const { name } = event.target;
    const newSections = sections.map((section, index) => {
      if (index !== position) return section;
      const newFields = section.fields.map((field) => (field.name === name ? { ...field, value, errorMessage: '' } : field));
      return { ...section, fields: newFields };
    });
    setSections(newSections);
  };

  const isNotCompleted = () => {
    if (!acceptTermsAndConditions) return true;
    return sections.some((section) => {
      const { fields = [] } = section;
      return fields.some(({ config = {}, value = '', status = {} }) => {
        const { value: statusValue } = status;
        const { required = false, hide = false } = config;
        if (!hide && !required && statusValue === REJECTED) return true;

        if (hide || !required) return false;
        return (!isValueAValidCharacter(value) || statusValue === REJECTED);
      });
    });
  };

  const findFields = (fieldsNames) => sections.reduce((acc, curr) => {
    const { fields } = curr;
    const filterValues = fields.filter(({ name }) => fieldsNames.includes(name));
    return [...acc, ...filterValues];
  }, []);

  const getCorrectPhone = (phoneText, addPrefix) => (addPrefix ? `+521${phoneText}` : phoneText);

  const transformDataToUpload = (filterFields, addPrefix = false) => {
    const newFields = sections.reduce((acc, curr) => {
      const { fields } = curr;
      return [...acc, ...fields];
    }, []).filter(({ name }) => !filterFields.includes(name)).map(({
      _id, config, value, fieldCatalog, name: fieldName,
    }) => ({
      _id,
      config,
      value: fieldName === PHONE ? getCorrectPhone(phoneValue, addPrefix) : value,
      fieldCatalog: {
        _id: fieldCatalog?._id || '',
      },
    }));
    return {
      company: COMPANY,
      fields: newFields,
    };
  };

  const createProcess = async (client, token) => {
    const dataToUpload = {
      flow,
      client,
      fields: [],
      product: productId,
    };
    return processAPI.createProcess(dataToUpload, COMPANY, token);
  };

  const saveExtraDataInStorage = (extraData) => {
    const authData = getAuth();
    storeAuth({
      ...authData,
      ...extraData,
    });
  };

  const saveCreditValues = async (process, token) => {
    const importe = prestamoValue;
    const { id: year } = MONTHLY_OPTIONS.find(({ value }) => value === valuesInput.year.value);
    const meses = year * 12;
    const aforo = (prestamoValue / propietyValue).toFixed(4);
    const monthlyPaid = monthlyPay;

    await Promise.all([
      fieldValueAPI.saveFieldValue({
        field: '65396fb7a97ccfb0db57040a',
        value: 'Si, ya tengo',
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '6515df2e5ab415f23cb0db85',
        value: convertirAMoneda(engancheValue),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '6515df5f5ab415f23cb0df1c',
        value: `${year} años`,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64dd5880067071a24e5dfa4a',
        value: convertirAMoneda(propietyValue),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64e3bfa365dc58ed7b91ab1c',
        value: convertirAMoneda(monthlyPaid),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64b71f8acbf8225d4573b07d',
        value: convertirAMoneda(importe),
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64b71fa1cbf8225d4573b07f',
        value: `${meses} meses`,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '64dbe1e7067071a24e3d83c2',
        value: `${aforo}`,
        process,
      }, token),
      fieldValueAPI.saveFieldValue({
        field: '662964051cad5eddba366490',
        value: convertirAMoneda(gastosEscrituraValue),
        process,
      }, token),
    ]);
  };

  const saveSharedFieldValues = async (fields, clientId, token) => {
    const dataSharedFieldValues = generateSharedFieldValues(fields, clientId);
    await Promise.all(
      dataSharedFieldValues.map(
        async (itemSharedFieldValue) => saveSharedFieldValuesData(itemSharedFieldValue, token),
      ),
    );
  };

  const generateCode = async (user, phone) => {
    await userAPI.generatePhoneCode(user);
    setPhoneValue(phone);
    setShowSendCodeDialog(true);
  };

  const checkPhoneAlreadyExists = async (user) => {
    const existsResponse = await userAPI.verifyPhoneAlreadyExists(user);
    const { exists = false } = existsResponse;
    return exists;
  };

  const getFullName = () => {
    const arrayFields = findFields([FIRSTNAME, SECOND_NAME, LASTNAME, SECOND_LASTNAME]);

    const fullName = arrayFields.reduce((acc, curr) => {
      const { value } = curr;
      return acc ? `${acc}${value ? ` ${value}` : ''}` : `${value}`;
    }, '');
    return fullName;
  };

  const sendCode = async () => {
    if (isNotCompleted()) return;
    startLoading();
    const [{ value: phone }] = findFields([PHONE]);

    const userObject = {
      company: COMPANY,
      phone: `+521${phone}`,
      fullName: getFullName(),
      model: 'client',
    };
    const phoneExist = await checkPhoneAlreadyExists(userObject);
    if (phoneExist) {
      setShowInformationDialog(true);
      endLoading();
      return;
    }
    await generateCode(userObject, phone);
    endLoading();
  };

  const createClient = async (dataToUpload) => {
    const clientReponse = await clientsAPI.saveFieldsData(dataToUpload);
    const { client, exists } = clientReponse;
    return { client, exists };
  };

  const fetchAutoNodes = async (autoExecutableNodes, token) => {
    const autoNodes = await Promise.all(autoExecutableNodes.map((nodeAutoExe) => nodeAPI.getNodeById(nodeAutoExe, token)));
    return autoNodes;
  };

  const executeAutoNodes = async (processId, token) => {
    const { autonodes = [] } = pageConfig;
    if (!autonodes) return;
    const autoNodes = await fetchAutoNodes(autonodes, token);
    if (Array.isArray(autoNodes) && autoNodes.length) {
      for (const autoNode of autoNodes) {
        await nodeAPI.autoExecuteNode({ nodeConfig: autoNode, process: processId }, token);
      }
    }
  };

  const updateClientByAgentAssigned = async (client, token) => {
    const { _id } = client;
    const newClient = { ...client, agentAssigned: agentId };
    await clientsAPI.updateClientData(_id, newClient, token);
  };

  const updateProcessByAgentAssigned = async (process, token) => {
    const { _id } = process;
    const newProcess = { ...process, agentAssigned: agentId };
    await processAPI.updateProcess(newProcess, _id, token);
  };

  const updateDataByAgentAssigned = async (client, process, token) => {
    if (!agentId) return;
    await updateProcessByAgentAssigned(process, token);
    await updateClientByAgentAssigned(client, token);
  };

  const getValueFromFieldsByName = (fieldName) => {
    const [foundField] = findFields([fieldName]);
    const { value = '' } = foundField;
    return value;
  };

  const normalFinish = async (client, token) => {
    const leadId = searchParams.get('client');
    const { process } = await createProcess(client, token);
    await saveCreditValues(process._id, token);
    const { normalizedFlowName } = await getFlowTypeHandler(process._id, token);
    const newTags = await createTags(process._id, normalizedFlowName, CREATE_ACCOUNT_NAME, token);
    await executeAutoNodes(process._id, token);
    await updateDataByAgentAssigned(client, { ...process, tags: newTags }, token);
    updateStateStepper(true);
    saveExtraDataInStorage({ process: process._id, flow, token });
    if (isVivienda) {
      TagManager.dataLayer({
        dataLayer: {
          clientId: client._id,
          page: 'CreateAccountPage',
        },
        dataLayerName: 'vivienda',
      });
    }
    await createUpdateTagGeneral({
      completed: true,
      fecha: nowDate(),
    }, 'iniciatramite');
    const leadObject = {
      processCreated: process._id,
      firstName: getValueFromFieldsByName(FIRSTNAME),
      lastName: getValueFromFieldsByName(LASTNAME),
      secondLastName: getValueFromFieldsByName(SECOND_LASTNAME),
    };
    await leadsAPI.updateLeadById(leadId, leadObject);
    storeProductData(defaultProduct);
    initTimeoutToken();
  };

  const onPerfilamientoExists = async () => {
    const localStepPosition = getCurrentStep();
    const currentPage = PROFILING_COTIZATION;
    const nextStep = localStepPosition + 1;
    const { steps: localSteps } = updateLocalStepsByStep(localStepPosition);
    updateCurrentPageOnLocalStep(nextStep, currentPage);
    googleAnalytics.perfilamientoCrediticio();
    navigate(localSteps[nextStep].route);
  };

  const onPerfilamientoEmpty = () => {
    const currentPage = PROFILING_EMPTY_MATRIZ;
    const localStepPosition = getCurrentStep();
    const nextStep = localStepPosition + 1;
    const { steps: localSteps } = updateLocalStepsByStep(localStepPosition);
    updateCurrentPageOnLocalStep(nextStep, currentPage);

    googleAnalytics.perfilamientoCrediticio();
    navigate(localSteps[nextStep].route);
  };

  const formatFromFieldValueModelToUpload = (fieldValues, includeFieldName = false) => {
    const newFieldValues = fieldValues.map(({ field, value }) => {
      const { _id, name } = field;
      if (includeFieldName) return { field: _id, value, name };
      return {
        field: _id,
        value,
      };
    });
    return newFieldValues;
  };

  const storeLocalFieldValues = () => { };

  const matrizExecFinish = async () => {
    const newClientData = transformDataToUpload([FULL_NAME_AGENTE], true);
    const { fields: clientFields } = newClientData;
    const { fields: sharedFieldValues } = transformDataToUpload([FULL_NAME_AGENTE]);

    await createLeadFromClientData(clientFields);
    const matrizBody = await getMatrizBody();
    const { fieldValues: matrizFieldValues } = matrizBody;
    const { values, matrizStatus, newConditions } = await execMatrizProfilling(matrizBody);
    if (newConditions) {
      await updateClientConditionsValues(newConditions);
    }
    googleAnalytics.matrizPerfilamiento(matrizStatus);
    if (matrizStatus === PERFILAMIENTO_EXIST && values.length) {
      const perfilamientoFieldValue = formatPerfilamientoFieldValue(values);
      const formattedFieldValues = formatFromFieldValueModelToUpload(matrizFieldValues, true);
      const catAndTasasFieldValues = await getCatTasaFieldValues(values);
      storeLocalFieldValues([...formattedFieldValues, ...catAndTasasFieldValues, perfilamientoFieldValue]);
      storeClientFieldsData(newClientData, sharedFieldValues);
      onPerfilamientoExists();
    } else {
      onPerfilamientoEmpty();
    }
  };

  const execFinishByType = async () => {
    switch (pageConfig.finishType) {
      case FINISH_TYPE_MATRIZ_VIVIENDA:
        return matrizExecFinish();
      case NORMAL_FINISH_TYPE:
      default:
        return sendCode();
    }
  };

  // eslint-disable-next-line consistent-return
  const onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      await execFinishByType();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    } finally {
      endLoading();
    }
  };

  const verifyCode = async (event, code) => {
    try {
      startLoading();
      setShowSendCodeDialog(false);
      const newClientData = transformDataToUpload([FULL_NAME_AGENTE], true);
      const dataToUpload = transformDataToUpload([FULL_NAME_AGENTE]);
      const correctPhone = `+521${phoneValue}`;
      const email = getValueFromFieldsByName(EMAIL);

      const userObject = {
        phone: correctPhone,
        code,
        model: 'client',
        roles: idRoles,
        email,
      };

      const { verified } = await userAPI.verifyPhoneCode(correctPhone, code);
      if (verified) {
        const { client, exists } = await createClient(newClientData);
        const { _id } = client;
        if (exists) {
          endLoading();
          showModal(true);
          return;
        }

        const userResponse = await userAPI.loginByPhoneCode(userObject);
        const { user } = userResponse;
        const { token } = user;
        const { fields } = dataToUpload;

        await saveSharedFieldValues(fields, _id, token);
        googleAnalytics.crearCuenta();
        storeAuth(user);
        await normalFinish(client, token);
      } else {
        setShowSendCodeDialog(true);
        setIsErrorPhoneValue({
          isError: true,
          errorMessage: 'Error al verificar el numero',
        });
      }
      endLoading();
    } catch (e) {
      console.log('e: ', e);
      if (e.response) {
        if (e.response.status === 422) {
          setShowInformationDialog(true);
        } else if (e.response.status === 409) {
          setShowModal(true);
        } else {
          showError('Error:', e.message);
        }
      } else if (e.message) {
        showError(e.message, e.name || 'Error al crear el usuario');
      } else {
        showError('Error inesperado');
      }
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const fetchClientRolesId = async () => {
    const payloadConfig = await getConfigs(LOGIN_PHONE_ID);
    const { clientRoles } = payloadConfig;
    setIdRoles(clientRoles);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const clientId = searchParams.get('client');
      const productAndFlow = await productAPI
        .getFlowAndProductByCompanyIdAndProductName(
          COMPANY,
          defaultProduct.name,
        );
      if (!productAndFlow) {
        endLoading();
        return;
      }
      setFlow(productAndFlow.flow);
      setProductId(productAndFlow.product);
      const flowName = getFlowName() || defaultProduct.flowName;
      const initialData = await getInitialDataInClientsByLink(clientId, flowName);
      const { processCreated = false } = initialData;
      setAgentId(initialData.agentId);
      setPageConfig({
        title: initialData.title,
        autonodes: initialData?.autonodes,
      });

      const tags = await getConfigTags(flowName);
      const newTags = tags.map((tag) => ({
        ...tag,
        completed: tag.name === 'cotizacion',
      }));
      initStepper({ steps: newTags, current: 1 });
      const actualStep = getActualStep(newTags);
      storeStepData({ ...actualStep, steps });

      await fetchClientRolesId();
      setSections(initialData.nodeDatas);
      const precio = convertirANumero(initialData.propiedadValor);
      const percentageNumber = INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE / 100;
      const newEnganche = (precio > 1200000) ? (precio * percentageNumber) : '';
      setValuesInput({
        ...CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES,
        precio: { ...CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES.precio, value: precio },
        enganche: { ...CREDITO_HIPOTECARIO_COTIZADOR_LINK_INITIAL_VALUES.enganche, value: parseInt((newEnganche).toFixed(2), 10) },
      });
      endLoading();
      setShowProcessAlreadyCreatedDialog(processCreated);
    } catch (e) {
      showError(e.message, e.name || 'Error trying to fetch  Data');
      endLoading();
    }
  };

  const onSubmitPhone = async (event, value) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      startLoading();
      const userObject = {
        company: COMPANY,
        phone: `+521${value}`,
        model: 'client',
        fullName: getFullName(),
      };

      const phoneExist = await checkPhoneAlreadyExists(userObject);

      if (phoneExist) {
        setShowInformationDialog(true);
        endLoading();
        return;
      }

      setSimpleInputDialogData(INITIAL_SIMPLE_INPUT_DIALOG);
      await generateCode(userObject, value);
      endLoading();
    } catch (e) {
      showError(e.message, e.name || 'Error trying to submit');
      endLoading();
      if (e instanceof AuthError) setShowModal(true);
    }
  };

  const backPhone = () => {
    setShowSendCodeDialog(false);
    const [inputPhone] = findFields([PHONE]);
    setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: true, input: inputPhone });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (steps && steps.length && steps[current].completed) {
      storeStepData({ stepRoute: steps[current + 1].route, currentStep: current + 1, steps });
      navigate(steps[current + 1].route);
    }
  }, [steps, current]);

  return (
    <>
      <AlertDialog
        onCancel={() => setShowModal(false)}
        onAccept={() => navigate(LOGIN_ROUTE)}
        title="Intentas registrate con un correo existente"
        showModal={showModal}
        onCancelText="Registrarse con otro correo"
        onAcceptText="Ir al login"
      />
      <SendCodeDialog
        onCancel={() => setShowSendCodeDialog(false)}
        backPhone={backPhone}
        onSubmit={verifyCode}
        phone={phoneValue}
        showModal={showSendCodeDialog}
        error={isErrorPhoneValue}
        title="Verifica tu celular"
        useEditPhone
        buttonText="Continuar"
      />
      <InformationDialog
        showModal={showInformationDialog}
        onSubmit={() => navigate(LOGIN_ROUTE)}
        buttonTitle="Ingresar a Chiplo"
        descripcion="El celular que estás ingresando con nosotros ya tiene una cuenta. Por favor ingresa a Chiplo con tu cuenta."
      />
      <InformationDialog
        showModal={showProcessAlreadyCreatedDialog}
        onSubmit={() => navigate(LOGIN_ROUTE, { replace: true })}
        buttonTitle="Ingresar a Chiplo"
        descripcion={`Hola ${getFullName()}, ya tienes una cuenta. Por favor ingresa a Chiplo con tu cuenta.`}
      />
      <SimpleInputDialog
        onCancel={() => setSimpleInputDialogData({ ...INITIAL_SIMPLE_INPUT_DIALOG, show: false })}
        onSubmit={onSubmitPhone}
        field={simpleInputDialogData.input}
        showModal={simpleInputDialogData.show}
        error={simpleInputDialogData.error}
        title={simpleInputDialogData.title}
        buttonTitle={simpleInputDialogData.buttonTitle}
      />
      <Loader
        open={isLoading}
      />
      <FormLayout
        submit={onSubmit}
        disabled={isNotCompleted() || showProcessAlreadyCreatedDialog}
        title={pageConfig.title}
        className={isVivienda ? classes.formContainer : ''}
        subTitle={pageConfig.subtitle}
        titleContainerClass={classes.title}
        buttonTitle={pageConfig.buttonTitle}
        renderHeader={isVivienda ? () => (
          <Container
            component="div"
            maxWidth={false}
            className={classes.header}
          >
            {pageConfig?.title ? <Typography className={classes.headerTitle}>{pageConfig.title}</Typography> : ''}
            {pageConfig?.subtitle ? <Typography className={classes.headerSubTitle}>{pageConfig?.subtitle}</Typography> : ''}
          </Container>
        ) : () => { }}
        renderButton={isVivienda ? () => (
          <Container
            className={classes.buttonContainer}
          >
            <Button
              className={classes.button}
              type="submit"
            >
              {pageConfig.buttonTitle}
            </Button>
          </Container>
        ) : () => { }}
      >
        <div
          className={classes.innerContainer}
        >
          {
            sections.map(({ fields, title }, index) => (
              <GroupFields
                fields={fields}
                title={title}
                customTitleClass={classes.customTitleClass}
                position={index}
                onChange={handleChange}
              />
            ))
          }
          <InputSimulator title="Datos de la propiedad" values={valuesInput} setValues={setValuesInput} />
          <TermsAndConditions
            product={{
              productName: 'creditohipotecario',
              page: 'createaccount',
            }}
            handleChange={() => setAcceptTermsAndConditions((value) => !value)}
            checked={acceptTermsAndConditions}
          />
        </div>
      </FormLayout>
    </>

  );
};
