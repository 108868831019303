import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: '600px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  tabs: {
    width: '100%',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    flex: 1,
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: '400',
    backgroundColor: '#FFFFFF',
    color: '#444345',
    paddingTop: '12px',
    textTransform: 'none',
    paddingBottom: '12px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '36px',
    },
    borderRadius: '5px',
    border: '1px solid #E2DBDB',
    marginLeft: '5px',
  },
  selected: {
    backgroundColor: '#2C1482',
    color: '#FFF',
  },
  firstTab: {
    marginLeft: '0px',
  },
  lastTab: {
  },
}));
