export const generateSharedFieldValues = (fields, ownerId, ownerModel = 'Client') => fields.reduce((acc, field) => {
  const { value = '', _id: fieldId } = field;
  if (value !== '') {
    acc.push({
      field: fieldId,
      value,
      ownerId,
      ownerModel,
    });
  }
  return acc;
}, []);

export const cleanPhonePrefix = (phone) => phone.replace('+521', '');
