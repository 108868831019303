import { decryptData, encryptData } from '../utils/encryption';

const PERFILADOR_DATA_KEY = btoa('PERFILADOR_DATA');

export const storePerfiladorLocalData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(PERFILADOR_DATA_KEY, encryptedData);
};

export const removePerfiladorLocalData = () => {
  window.localStorage.removeItem(PERFILADOR_DATA_KEY);
};

export const getPerfiladorLocalData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(PERFILADOR_DATA_KEY);
  if (!jsonDataEncrypted || jsonDataEncrypted === undefined) return {};
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

export const storeExtraPerfiladorData = (data) => {
  const currentAgentData = getPerfiladorLocalData();
  const newAgentData = { ...currentAgentData, ...data };
  storePerfiladorLocalData(newAgentData);
};

const getItem = (key) => {
  try {
    const agentData = getPerfiladorLocalData();
    return agentData[key];
  } catch {
    return undefined;
  }
};

export const getPerfiladorPropietyValue = () => getItem('clientPropietyValue');

export const getPerfiladorClientIngresos = () => getItem('ingresos');

export const getPerfiladorClientValuePropNumber = () => getItem('valuePropNumber');

export const getPerfiladorActiveTab = () => getItem('activeTab');
