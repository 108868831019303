import React, {
  memo, useCallback, useRef, useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import InputBase from '../InputBase';
import useEnabler from '../../../hooks/useEnabler';
import { isValidateFormat } from './validate';

const ERROR_MESSAGE_EMPTY = 'Rellena este campo';

const InputEmpty = ({
  value,
  onChange,
  error,
  label,
  required,
  typeValidation,
  helperText,
  onKeyDown,
  multiline,
  isValid,
  ...props
}) => {
  const {
    isEnabled: isError,
    enable: enableError,
    disable: disableError,
  } = useEnabler();
  const [isTouched, setIsTouched] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!required) return;
    if (!isValid) return;
    if (isTouched) {
      const isValidated = isValidateFormat(value, typeValidation);
      if (isValidated === 1) {
        disableError();
        inputRef.current.setCustomValidity('');
      } else if (isValidated === 0) {
        enableError();
        inputRef.current.setCustomValidity(ERROR_MESSAGE_EMPTY);
      }
    }
  }, [enableError, isTouched, value, typeValidation, required, isValid]);

  const handleOnChange = useCallback((event) => {
    const { target } = event;
    const { value: _value } = target;
    if (!required) {
      onChange(event, _value);
      return;
    }
    setIsTouched(true);
    onChange(event, _value);
  }, [onChange, enableError, disableError]);

  return (
    <InputBase
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      multiline={multiline}
      value={value}
      onChange={handleOnChange}
      ref={inputRef}
      label={`${label}${required ? ' *' : ''}`}
      error={isError || error}
      helperText={isError ? ERROR_MESSAGE_EMPTY : helperText}
    />
  );
};

InputEmpty.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  typeValidation: PropTypes.string,
  helperText: PropTypes.string,
  onKeyDown: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  rejections: PropTypes.any,
  isValid: PropTypes.bool,
};

InputEmpty.defaultProps = {
  required: false,
  error: false,
  label: '',
  multiline: false,
  typeValidation: 'string',
  helperText: '',
  onKeyDown: () => { },
  isValid: false,
  rejections: null,
};

export default memo(InputEmpty);
