import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    maxWidth: '700px',
  },
  tableContainer: {
    overflowX: 'hidden',
    borderRadius: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(2, 0),
  },
  table: {
    border: '1px solid #f8f8f8',
  },
  cellContainer: {
    border: 'unset',
    padding: theme.spacing(1.5),
    minWidth: '80px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '170px',
      padding: `${theme.spacing(2.2)}px ${theme.spacing(1.5)}px`,
    },
  },
  cellContainerCentered: {
    border: 'unset',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    minWidth: '100px',
    [theme.breakpoints.up('sm')]: {
      minWidth: '170px',
    },
  },
  rowContainer: {
    backgroundColor: '#f7fafd',
  },
  rowWhiteColor: {
    backgroundColor: '#FFFFFF',
  },
  image: {
    maxHeight: '50px',
    maxWidth: '80px',
    [theme.breakpoints.up('sm')]: {
      maxHeight: '70px',
      maxWidth: '120px',
    },
  },
  imageContent: {
    minWidth: '30px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minWidth: '180px',
    },
  },
  imageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      padding: '0 10px 0 0',
    },
  },
  propContainerCentered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  propContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
    height: '100%',
  },
  propTitle: {
    fontWeight: 700,
    fontFamily: 'Open Sans',
    fontSize: '1rem',
    lineHeight: '1.625rem',
    paddingRight: '4px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.55rem',
      lineHeight: '2.25rem',
    },
  },
  propSubtitle: {
    fontWeight: 400,
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: 'rgba(48, 48, 48, 0.63)',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
    },
  },
  propValue: {
    fontSize: '0.75rem',
    lineHeight: '1.0212rem',
    fontFamily: 'Open Sans',
    maxWidth: '160px',
    display: 'inline-block',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      maxWidth: '220px',
    },
  },
  productClass: {
    fontSize: '0.65rem',
    color: 'rgba(48, 48, 48, 0.63)',
    fontFamily: 'Open Sans',
    fontWeight: 400,
    lineHeight: '0.75rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.85rem',
      lineHeight: '1rem',
    },
  },
  difference: {
    fontSize: '0.75rem',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    lineHeight: '1.0212rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  },
  titleContainer: {
    fontFamily: 'Open Sans',
    fontSize: '1.3rem',
    fontWeight: 700,
    lineHeight: '1.575rem',
    textAlign: 'center',
    color: '#5F5F5F',
    padding: `${theme.spacing(2)}px 0px ${theme.spacing(2)}px 0px`,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
    },
  },
  cellHead: {
    fontFamily: 'Open Sans',
    fontSize: '0.8rem',
    fontWeight: 600,
    lineHeight: '1.1rem',
    textAlign: 'center',
    color: '#3A3A3A',
    padding: '20px 0px 0px 0px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
    },
  },
}));
