/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */

import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import InputNumber from './InputNumber';
import InputRFC from './InputRFC';
import InputCLABE from './InputCLABE';
import InputCURP from './InputCURP';
import InputCurrency from './InputCurrency';
import InputPhone from './InputPhone';
import SeletBase from './SeletBase';
import InputBaseDatePicker from './InputBaseDatePicker';
import InpuAddress from './InpuAddress';
import InputRejected from './InputRejected';
import InputCheckboxOld from './InputCheckboxOld';
import InputCheckbox from './InputCheckbox';
import InputLastDigits from './InputLastDigits';
import InputEmail from './InputEmail';
import InputPassword from './InputPassword';
import InputTable from './InputTable';
import InputArrayText from './InputArrayText';
import InputTableSelect from './InputTableSelect';
import InputPrice from './InputPrice';
import SeletSimple from './SeletSimple';
import InputUploadDocument from './InputUploadDocument';
import { CheckboxList } from '../CheckboxList';
import InputPriceStyled from './InputPriceStyled';
import RadioGroupInput from './Radio';
import InputPercentage from './InputPercentage';
import { getFilesFormatToUpload } from '../../utils/fields';

export const TYPES = {
  TEXT: 'text',
  RESPUESTA_CORTA: 'respuesta corta',
  TEXT_AREA: 'textarea',
  PRICE: 'precio',
  PRICE_STYLED: 'precio_styled',
  SELECT_SIMPLE: 'select_simple',
  RESPUESTA_LARGA: 'respuesta larga',
  EMAIL: 'email',
  NUMBER: 'number',
  CANTIDAD: 'cantidad',
  NUMERO: 'número',
  PASSWORD: 'password',
  RFC: 'rfc',
  CLABE: 'clabe',
  CURP: 'curp',
  CURRENCY: 'currency',
  PHONE: 'phone',
  CELULAR: 'celular',
  SELECT: 'select',
  LISTA: 'lista',
  DATE_PICKER: 'datepicker',
  FECHA: 'fecha',
  AUTOCOMPLETE: 'autocomplete',
  ADDRESS: 'address',
  CHECKBOX: 'checkbox',
  CHECKBOX_LISTA: 'checkboxlist',
  CHECKBOX_OLD: 'checkbox old',
  LAST_DIGITS: 'cardnumber',
  INPUT_TABLE: 'input table signers',
  INPUT_ARRAY_RFC: 'inputarrayrfc',
  INPUT_TABLE_SELECT: 'input table select',
  INPUT_UPLOAD_DOCUMENT: 'document',
  RADIOGROUP: 'radiogroup',
  INPUT_PERCENTAGE: 'percentage',
};

const Input = ({
  id,
  name,
  label,
  value,
  isEmpty,
  type,
  required,
  disabled,
  hide,
  parentValue,
  className,
  classNameSelect,
  classNameLabel,
  extraInputClassName,
  fullWidth,
  startAdornment,
  endAdornment,
  isFloat,
  options,
  format,
  onChange,
  onKeyDown,
  rejections,
  status,
  defaultValue,
  errorMessage,
  headers,
  handleHeadersAndValues,
  onDateChange,
  keysMatch,
  internSelectLabel,
  validations,
  error,
  showPasswordValidity,
  multiUpload,
  disableCompleteIcon,
  makeRequired,
  hideLabel,
  uploadFormats,
}) => {
  const MAPPED_BASE_PROPS = useMemo(
    () => ({
      id,
      name,
      label,
      value,
      isEmpty,
      required,
      disabled,
      className,
      classNameSelect,
      classNameLabel,
      extraInputClassName,
      fullWidth,
      startAdornment,
      endAdornment,
      onChange,
      onKeyDown,
      rejections,
      defaultValue,
      hide,
      errorMessage,
      headers,
      handleHeadersAndValues,
      onDateChange,
      keysMatch,
      internSelectLabel,
      validations,
      showPasswordValidity,
      multiUpload,
      disableCompleteIcon,
      makeRequired,
      hideLabel,
      uploadFormats,
    }),
    [
      id,
      name,
      label,
      value,
      isEmpty,
      required,
      disabled,
      className,
      classNameSelect,
      classNameLabel,
      extraInputClassName,
      fullWidth,
      startAdornment,
      endAdornment,
      onChange,
      onKeyDown,
      rejections,
      defaultValue,
      hide,
      errorMessage,
      headers,
      handleHeadersAndValues,
      onDateChange,
      keysMatch,
      internSelectLabel,
      showPasswordValidity,
      multiUpload,
      disableCompleteIcon,
      makeRequired,
      hideLabel,
      uploadFormats,
    ],
  );

  const typeLowerCase = type.toLowerCase();

  if ((hide && parentValue) && status !== 'Rechazado') return <></>;

  return (() => {
    switch (typeLowerCase) {
      case TYPES.EMAIL:
        return (
          <InputEmail
            {...MAPPED_BASE_PROPS}
            type={TYPES.EMAIL}
          />
        );

      case TYPES.NUMBER:
      case TYPES.NUMERO:
      case TYPES.CANTIDAD:
        return (
          <InputNumber
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
            isFloat={isFloat}
          />
        );
      case TYPES.INPUT_UPLOAD_DOCUMENT:
        return (
          <InputUploadDocument
            accept={getFilesFormatToUpload(uploadFormats)}
            onDrop={onChange}
            disabled={disabled}
            label={label}
            value={value}
            multiple={multiUpload}
            disableCompleteIcon={disableCompleteIcon}
          />
        );

      case TYPES.PASSWORD:
        return (
          <InputPassword
            {...MAPPED_BASE_PROPS}
            type={TYPES.PASSWORD}
          />
        );
      case TYPES.PRICE_STYLED:
        return (
          <InputPriceStyled
            {...MAPPED_BASE_PROPS}
            error={error}
          />
        );
      case TYPES.PRICE:
        return (
          <InputPrice
            {...MAPPED_BASE_PROPS}
            error={error}
          />
        );

      case TYPES.CHECKBOX:
        return (
          <InputCheckbox
            {...MAPPED_BASE_PROPS}
            type={TYPES.CHECKBOX}
            options={options}
          />
        );
      case TYPES.CHECKBOX_LISTA:
        return (
          <CheckboxList
            {...MAPPED_BASE_PROPS}
            type={TYPES.CHECKBOX_LISTA}
            options={options}
          />
        );
      case TYPES.CHECKBOX_OLD:
        return (
          <InputCheckboxOld
            {...MAPPED_BASE_PROPS}
            type={TYPES.CHECKBOX}
          />
        );

      case TYPES.LAST_DIGITS:
        return (
          <InputLastDigits
            {...MAPPED_BASE_PROPS}
            type={TYPES.LAST_DIGITS}
          />
        );

      case TYPES.RFC:
        return (
          <InputRFC
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
          />
        );
      case TYPES.INPUT_ARRAY_RFC:
        return (
          <InputArrayText
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
          />
        );

      case TYPES.CLABE:
        return (
          <InputCLABE
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
          />
        );

      case TYPES.CURP:
        return (
          <InputCURP
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
          />
        );

      case TYPES.CURRENCY:
        return (
          <InputCurrency
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
            isFloat={isFloat}
          />
        );

      case TYPES.PHONE:
      case TYPES.CELULAR:
        return (
          <InputPhone
            {...MAPPED_BASE_PROPS}
            type={TYPES.NUMBER}
          />
        );

      case TYPES.TEXT_AREA:
      case TYPES.RESPUESTA_LARGA:
        return (
          <InputRejected
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
            multiline
          />
        );

      case TYPES.SELECT:
      case TYPES.LISTA:
      case TYPES.AUTOCOMPLETE:
        return (
          <SeletBase
            {...MAPPED_BASE_PROPS}
            options={options}
          />
        );
      case TYPES.SELECT_SIMPLE:
        return (
          <SeletSimple
            {...MAPPED_BASE_PROPS}
            options={options}
          />
        );

      case TYPES.DATE_PICKER:
      case TYPES.FECHA:
        return (
          <InputBaseDatePicker
            {...MAPPED_BASE_PROPS}
            format={format}
          />
        );

      case TYPES.ADDRESS:
        return (
          <InpuAddress
            {...MAPPED_BASE_PROPS}
          />
        );
      case TYPES.INPUT_TABLE:
        return (
          <InputTable {...MAPPED_BASE_PROPS} />
        );
      case TYPES.INPUT_TABLE_SELECT:
        return (
          <InputTableSelect {...MAPPED_BASE_PROPS} />
        );
      case TYPES.RADIOGROUP:
        return (
          <RadioGroupInput
            {...MAPPED_BASE_PROPS}
            options={options}
          />
        );
      case TYPES.INPUT_PERCENTAGE:
        return (
          <InputPercentage {...MAPPED_BASE_PROPS} />
        );
      case TYPES.TEXT:
      case TYPES.RESPUESTA_CORTA:
      default:
        return (
          <InputRejected
            {...MAPPED_BASE_PROPS}
            type={TYPES.TEXT}
          />
        );
    }
  })();
};

Input.propTypes = {
  id: PropTypes.any,
  name: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  isEmpty: PropTypes.any,
  type: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  hide: PropTypes.bool,
  validations: PropTypes.arrayOf(PropTypes.func),
  parentValue: PropTypes.string,
  className: PropTypes.string,
  classNameSelect: PropTypes.string,
  classNameLabel: PropTypes.string,
  extraInputClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  startAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  endAdornment: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  isFloat: PropTypes.bool,
  options: PropTypes.array,
  format: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  rejections: PropTypes.any,
  status: PropTypes.any,
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.string,
  headers: PropTypes.any,
  handleHeadersAndValues: PropTypes.func,
  onDateChange: PropTypes.func,
  keysMatch: PropTypes.array,
  internSelectLabel: PropTypes.string,
  error: PropTypes.object,
  showPasswordValidity: PropTypes.bool,
  multiUpload: PropTypes.bool,
  disableCompleteIcon: PropTypes.bool,
  makeRequired: PropTypes.bool,
  hideLabel: PropTypes.bool,
  uploadFormats: PropTypes.arrayOf(PropTypes.string),
};

Input.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  value: undefined,
  isEmpty: undefined,
  type: TYPES.TEXT,
  required: false,
  disabled: false,
  validations: [],
  hide: false,
  parentValue: '',
  className: '',
  classNameSelect: '',
  classNameLabel: '',
  extraInputClassName: '',
  fullWidth: true,
  startAdornment: '',
  endAdornment: '',
  isFloat: true,
  options: [],
  format: 'DD/MM/YYYY',
  onChange: () => { },
  onKeyDown: () => { },
  rejections: null,
  status: '',
  defaultValue: '',
  errorMessage: '',
  headers: undefined,
  handleHeadersAndValues: () => { },
  onDateChange: () => { },
  keysMatch: [],
  showPasswordValidity: true,
  internSelectLabel: 'Selecciona una opción',
  error: {
    isValid: false,
    errorMessage: '',
  },
  multiUpload: false,
  disableCompleteIcon: false,
  makeRequired: false,
  hideLabel: false,
  uploadFormats: [],
};

export default memo(Input);
