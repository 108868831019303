import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';

// import { ImagesSlider } from '../../../../components/ImagesSlider';
import { staticLandingData } from '../../../../data';

import useStyles from './useStyles';
import { BoxRounded } from '../../../../components/BoxRounded';
import { getEntitiesLandingData } from '../../../../api/entity';
import { COMPANY } from '../../../../constants';
import { CarouselSlider } from '../../../../components/CarouselSlider';

export const EntitiesSection = () => {
  const classes = useStyles();

  const [dataEntities, setDataEntities] = useState(staticLandingData.dataEntities);

  const fetchEntitiesData = async () => {
    const resp = await getEntitiesLandingData(COMPANY);
    const images = resp.map(({ logoURL }) => logoURL);
    setDataEntities({
      ...dataEntities,
      images,
    });
  };

  useEffect(() => {
    fetchEntitiesData();
  }, []);

  const handleClick = () => {
    window.open(dataEntities.buttonURL, '_blank');
  };

  return (
    <Box component="section" className={classes.sectionContainer}>
      <BoxRounded
        title={dataEntities.title}
      >
        <CarouselSlider
          images={dataEntities.images || []}
          classNames={{
            img: classes.img,
            imgContainer: classes.imgContainer,
          }}
        />
        <Box
          className={classes.boxContainer}
        >
          <Button
            className={classes.button}
            onClick={handleClick}
          >
            {dataEntities.buttonText}
          </Button>
        </Box>

      </BoxRounded>
    </Box>
  );
};
