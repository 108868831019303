import React from 'react';
import PropTypes from 'prop-types';

import { MenuAppBar, StepperBar } from '../../../../components';

export const MainLayout = ({
  showStepperBar = true,
  navigateOnLogoClick,
  children,
  displayAuthUser = true,
}) => (
  <MenuAppBar
    navigateOnLogoClick={navigateOnLogoClick}
    displayAuthUser={displayAuthUser}
  >
    {showStepperBar && <StepperBar />}
    {children}
  </MenuAppBar>
);

MainLayout.propTypes = {
  showStepperBar: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  navigateOnLogoClick: PropTypes.bool,
  displayAuthUser: PropTypes.bool,
};

MainLayout.defaultProps = {
  showStepperBar: true,
  navigateOnLogoClick: false,
  displayAuthUser: true,
};
