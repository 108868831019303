import React from 'react';

import { Typography } from '@material-ui/core';

import useStyles from './useStyles';
import { DEFAULT_PLAZO_20 } from '../../../../constants';

export const FooterTextPerfilador = () => {
  const classes = useStyles();

  return (
    <Typography className={classes.footerText}>
      {`Estimación realizada con 10% de enganche y plazo de ${DEFAULT_PLAZO_20} años. Las condiciones finales serán definidas al autorizar el crédito.`}
    </Typography>
  );
};
