import React, {
  useContext,
  useMemo,
} from 'react';

import PropTypes from 'prop-types';
import {
  Box, Divider, Grid, Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { Button } from '../../../../components';

import {
  CALC_MODE_PRECIO_INMUEBLE,
  DEFAULT_PLAZO_20,
  PERFILADOR_VARIANTE_LANDING,
} from '../../../../constants';
import { ConditionAmmountList } from '../ConditionsAmmountList';
import { PerfiladorInput } from '../PerfiladorInput';
import { FooterTextPerfilador } from '../FooterTextPerfilador';
import { TabsContext } from '../../../../context/tabs/tabsContext';
import usePerfilador from '../../../../hooks/usePerfilador';

import useStyles from './useStyles';

export const PerfiladorPrecioInmueble = ({ onSubmit, buttonTitle, showFooter }) => {
  const classes = useStyles();
  const {
    inputValue: propietyValue,
    inputNumber: valuePropNumber,
    pagoMensual = 0,
    montoCredito = 0,
    ingresosRecomendados = 0,
    montoGastosEscritura = 0,
    onChange,
  } = usePerfilador({ calcMode: CALC_MODE_PRECIO_INMUEBLE, useDesembolsoMode: true });

  const { tabsExtraClasses, tabsVariant } = useContext(TabsContext);

  const inputData = {
    label: '',
    value: '',
    placeHolder: '$1,000,000',
    required: true,
    type: 'currency',
    disabled: false,
    hideLabel: true,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const storageData = {
      importe: montoCredito,
      meses: DEFAULT_PLAZO_20 * 12,
      aforo: (montoCredito / valuePropNumber).toFixed(4),
      enganche: valuePropNumber * 0.1,
      year: DEFAULT_PLAZO_20,
      plazo: `${DEFAULT_PLAZO_20} años`,
      mensualidad: pagoMensual,
      propietyValue: valuePropNumber,
      propietyValueCurrency: propietyValue,
      montoGastosEscritura,
    };
    onSubmit({
      storageData,
      event,
    });
  };

  const conditionsAmmountData = [
    {
      label: 'Pago mensual:',
      ammountNumber: pagoMensual,
    },
    {
      label: 'Monto del crédito:',
      ammountNumber: montoCredito,
    },
    {
      label: 'Ingresos mensuales:',
      ammountNumber: ingresosRecomendados,
    },
  ];

  const inputLabel = useMemo(() => {
    switch (tabsVariant) {
      case PERFILADOR_VARIANTE_LANDING:
        return 'Valor aproximado de tu propiedad';
      default:
        return 'Valor de la propiedad:';
    }
  }, [tabsVariant]);

  return (
    <>
      <Grid
        container
        spacing={2}
        className={clsx(classes.container, {
          [tabsExtraClasses?.gridContainer]: tabsExtraClasses?.gridContainer,
        })}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={clsx(classes.grid, {
            [tabsExtraClasses?.gridLeft]: tabsExtraClasses?.gridLeft,
          })}
        >
          <PerfiladorInput
            titleLabel={inputLabel}
            inputData={inputData}
            value={propietyValue}
            onChange={onChange}
            classes={{
              input: clsx(classes.input, {
                [tabsExtraClasses?.input]: tabsExtraClasses.input,
              }),
              title: clsx(classes.title, {
                [tabsExtraClasses?.title]: tabsExtraClasses.title,
              }),
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          className={clsx(classes.gridRight, {
            [tabsExtraClasses?.gridRight]: tabsExtraClasses.gridRight,
          })}
        >
          <Divider orientation="vertical" flexItem />
          <Box className={classes.textConditions}>
            <Typography className={clsx(classes.subtitleConditions, {
              [tabsExtraClasses?.subtitleConditions]: tabsExtraClasses.subtitleConditions,
            })}
            >
              Condiciones estimadas
            </Typography>
          </Box>
          <ConditionAmmountList
            items={conditionsAmmountData}
            customTitleClassName={tabsExtraClasses?.titleClassName}
            customValueClassName={tabsExtraClasses?.valueClassName}
          />
        </Grid>
      </Grid>
      <Box className={classes.btnContainer}>
        <Button
          className={clsx(classes.btn, {
            [tabsExtraClasses?.btn]: tabsExtraClasses.btn,
          })}
          onClick={handleSubmit}
        >
          {buttonTitle}
        </Button>
      </Box>
      {showFooter && <FooterTextPerfilador />}
    </>
  );
};

PerfiladorPrecioInmueble.propTypes = {
  onSubmit: PropTypes.func,
  buttonTitle: PropTypes.string,
  showFooter: PropTypes.bool,
};

PerfiladorPrecioInmueble.defaultProps = {
  onSubmit: () => {},
  buttonTitle: '',
  showFooter: true,
};
