import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Container, Typography } from '@material-ui/core';

import Input from '../Input';
import { ButtonCredit } from '../Simulator/ButtonCredit';
import { CurrencyLabel } from '../Simulator/CurrencyLabel';

import { convertirAMoneda } from '../../utils/currency';
import { isGreaterThanValue, isValidPercentage } from '../../utils/simulator.utils';

import styles from './useStyles';
import { useSimulator } from '../../hooks/useSimulator';

import {
  DEFAULT_TASA,
  DEFAULT_TASAS,
  INPUT_NAME_DESEMBOLSO,
  INPUT_NAME_ENGANCHE,
  INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
  INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
  MINIMUM_CREDIT_VALUE,
} from '../../constants';

export const CreditConditionsSimulator = ({
  isVivienda,
  onSubmit,
  initialValues,
  buttonTitle,
  hidePlazo,
  useDesembolsoMode,
}) => {
  const classes = styles();
  const {
    prestamoValue,
    engancheValue,
    percentage,
    montoGastosEscritura,
    monthlyPay,
    currentYear,
    values,
    handleChange,
  } = useSimulator({
    initialData: initialValues,
    tasas: DEFAULT_TASAS,
    TASA: DEFAULT_TASA,
    useDesembolsoMode,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const storageData = {
      montoMensual: values?.montoMensual?.value,
      sueldoMensualBruto: values?.montoMensual?.value,
      importe: prestamoValue,
      meses: currentYear * 12,
      aforo: (prestamoValue / values.precio.value).toFixed(4),
      enganche: engancheValue,
      year: currentYear,
      plazo: `${currentYear} años`,
      mensualidad: monthlyPay,
      propietyValue: values?.precio.value,
      montoGastosEscritura,
    };
    onSubmit({ storageData, event });
  };

  return (
    <Container
      component="form"
      onSubmit={handleSubmit}
      method="post"
      autoComplete="off"
      className={classes.formContainer}
      disableGutters
      maxWidth={false}
    >
      <Box
        className={clsx(classes.innerContainer, {
          [classes.viviendaInnerContainer]: isVivienda,
        })}
      >
        <Box className={classes.firtInputElement}>
          <Input
            className={classes.inputPrecio}
            label={values.precio.label}
            type={values.precio.type}
            name={values.precio.name}
            value={values.precio.value}
            required={values.precio.required}
            isEmpty={values.precio.isEmpty}
            validations={[
              (inputValue) => isGreaterThanValue(
                inputValue,
                MINIMUM_CREDIT_VALUE,
                `Valor no puede ser menor a ${convertirAMoneda(
                  MINIMUM_CREDIT_VALUE,
                )}`,
              ),
            ]}
            onChange={handleChange}
          />
        </Box>
        <Box className={classes.secondInputElement}>
          {Object.keys(values).includes(INPUT_NAME_ENGANCHE) && (
            <Box className={classes.precioContainer}>
              <Input
                disabled={values.enganche.disabled}
                className={classes.engancheInput}
                label={values.enganche.label}
                type={values.enganche.type}
                name={values.enganche.name}
                value={values.enganche.value}
                required={values.enganche.required}
                isEmpty={values.enganche.isEmpty}
                onChange={handleChange}
                error={values.enganche.error}
                validations={[
                  (inputValue) => isValidPercentage(
                    inputValue,
                    values.precio.value,
                    INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
                    `Valor no puede ser menor a ${INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE}%`,
                  ),
                  (inputValue) => isGreaterThanValue(
                    values.precio.value,
                    inputValue,
                    'Enganche no puede ser mayor al precio',
                  ),
                ]}
                endAdornment={(
                  <Typography
                    className={clsx(classes.enganche, {
                      [classes.invalidate]:
                      percentage
                      < INPUT_SIMULATOR_MINIMUN_ENGANCHE_PERCENTAGE,
                    })}
                  >
                    {`${percentage}%`}
                  </Typography>
                )}
              />
            </Box>
          )}
          {(Object.keys(values).includes(INPUT_NAME_DESEMBOLSO) && useDesembolsoMode) && (
            <Box className={classes.precioContainer}>
              <Input
                disabled={values.desembolso.disabled}
                className={classes.engancheInput}
                label={values.desembolso.label}
                type={values.desembolso.type}
                name={values.desembolso.name}
                value={values.desembolso.value}
                required={values.desembolso.required}
                isEmpty={values.desembolso.isEmpty}
                onChange={handleChange}
                error={values.desembolso.error}
                validations={[
                  (inputValue) => isValidPercentage(
                    inputValue,
                    values.precio.value,
                    INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
                    `Valor no puede ser menor a ${INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE}%`,
                  ),
                  (inputValue) => isGreaterThanValue(
                    values.precio.value,
                    inputValue,
                    'Enganche no puede ser mayor al precio',
                  ),
                ]}
                endAdornment={(
                  <Typography
                    className={clsx(classes.enganche, {
                      [classes.invalidate]:
                        percentage < INPUT_SIMULATOR_MINIMUM_DESEMBOLSO_PERCENTAGE,
                    })}
                  >
                    {`${percentage}%`}
                  </Typography>
                )}
              />
            </Box>
          )}
          {hidePlazo ? null : (
            <Box className={classes.yearContainer}>
              <Input
                className={classes.inputBorder}
                label={values.year.label}
                type={values.year.type}
                name={values.year.name}
                value={values.year.value}
                required={values.year.required}
                isEmpty={values.year.isEmpty}
                onChange={handleChange}
                fullWidth
                options={values.year.options}
              />
            </Box>
          )}
        </Box>
        <Box
          className={clsx(classes.thirdInputElement, {
            [classes.hidden]: isVivienda,
          })}
        >
          <Box className={clsx(classes.element, classes.borderRight)}>
            <CurrencyLabel
              title={values.prestamo.label}
              money={prestamoValue}
              format={values.prestamo.format}
            />
          </Box>
          <Box className={classes.element}>
            <CurrencyLabel
              title={values.monthlyPaid.label}
              money={monthlyPay}
              format={values.monthlyPaid.format}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.containerButton}>
        <ButtonCredit
          className={classes.button}
          variant="contained"
          type="submit"
        >
          {buttonTitle}
        </ButtonCredit>
      </Box>
    </Container>
  );
};

CreditConditionsSimulator.propTypes = {
  onSubmit: PropTypes.func,
  isVivienda: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  hidePlazo: PropTypes.bool,
  useDesembolsoMode: PropTypes.bool,
};

CreditConditionsSimulator.defaultProps = {
  onSubmit: () => { },
  isVivienda: false,
  hidePlazo: false,
  useDesembolsoMode: false,
};
