export const RFC_FIELD_DEUDOR = 'rfcFieldDeudor';
export const RECEPTOR_FIELD = 'nombreReceptor';

export const TABLE_HEADERS_SIGNERS = [
  'Nombre',
  'Apellido Paterno',
  'Apellido materno',
  'RFC',
];
export const TABLE_COLUMNS_SIGNERS = [
  'firstName',
  'lastName',
  'secondLastName',
  'rfc',
];
export const TABLE_MESSAGE_NO_SIGNERS = 'No se han agregado firmantes';

export const FIADOR_SIGNER_ROLE = 'fiador';

export const PERFILAMIENTO_FIELD_ID = '64c038a036c0be68462c0d48';
export const PERFILAMIENTO_FIELD_NAME = 'perfilamientoCrediticio';
export const IMPORTE_FIELD_ID = '64b71f8acbf8225d4573b07d';
export const PLAZO_FIELD_ID = '64b71fa1cbf8225d4573b07f';
export const AFORO_FIELD_ID = '64dbe1e7067071a24e3d83c2';
export const ENGANCHE_FIELD_ID = '6515df2e5ab415f23cb0db85';
export const PLAZO_YEARS_FIELD_ID = '6515df5f5ab415f23cb0df1c';
export const PAGO_MENSUAL_FIELD_ID = '64e3bfa365dc58ed7b91ab1c';
export const PROPIEDAD_VALOR_FIELD_ID = '64dd5880067071a24e5dfa4a';
export const VALOR_ESTADO_PROPIEDAD_FIELD_ID = '64b8517fe33cb957c544150e';
export const GASTOS_ESCRITURA_FIELD_ID = '662964051cad5eddba366490';

export const OPTION_SELECTED_FIELD_ID = '66a84fda81db963ffac0a8d5';
export const OPTION_SELECTED_FIELD_NAME = 'optionSelected';

export const COFINANCIAMIENTO_FIELD_ID = '64dac1f2067071a24e24d588';
export const COFINANCIAMIENTO_FIELD_NAME = 'cofinanciamiento';

export const NOMBRE_INMOBILIARIA_FIELD_ID = '66031f2bc59f7d33cde63797';
export const AGENTE_INMOBILIARIO_FIELD_ID = '66031f2bc59f7d33cde63798';

export const DEFAUT_FIELD_UPLOAD_LABEL = 'Agrega un PDF o imagen';
export const LIST_FIELDTYPE = {
  name: 'lista',
  _id: '5db9cb43e26163f4aca2e897',
};
