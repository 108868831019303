/* eslint-disable react/forbid-prop-types */
// PerfiladorInput.js
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import Input from '../../../../components/Input';

export const PerfiladorInput = ({
  titleLabel, inputData, value, onChange, classes,
}) => (
  <Box className={classes.inputContainer}>
    <Typography className={classes.title}>
      {titleLabel}
    </Typography>
    <Input
      label={inputData.label}
      placeHolder={inputData.placeHolder}
      required={inputData.required}
      type={inputData.type}
      disabled={inputData.disabled}
      onChange={onChange}
      value={value}
      hideLabel={inputData?.hideLabel}
      className={classes.input}
    />
  </Box>
);

PerfiladorInput.propTypes = {
  titleLabel: PropTypes.string,
  inputData: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

PerfiladorInput.defaultProps = {
  titleLabel: '',
};
