import { logout } from '../api/auth';
import { LOGIN_ROUTE } from '../constants';
import {
  removeFieldValuesData,
  removeFieldValuesSectionsData,
  removePerfilamientoLeadData,
  removeLocalAgentData,
  removeLocalEntityData,
} from '../storage';
import { removeAuth } from './auth';
import { removeClientData } from './clientData';
import { removeCredit } from './credit';
import { removeCreditProcess } from './creditsProcess';
import { removeFlowData } from './flows';
import { removeLeadData } from './leads';
import { removeProductData } from './product';
import { removeStepData } from './stepper';
import { removeThemeData } from './theme';

export const deleteIndexDB = async () => {
  const dbs = await window.indexedDB.databases();
  dbs.forEach((db) => window.indexedDB.deleteDatabase(db.name));
};

const removeLocalData = () => {
  removeAuth();
  removeStepData();
  removeCredit();
  removeThemeData();
  removeProductData();
  removeFieldValuesData();
  removeFlowData();
  removeClientData();
  removeLeadData();
  removeFieldValuesSectionsData();
  removeLocalAgentData();
  removeLocalEntityData();
  removeCreditProcess();
  removePerfilamientoLeadData();
};

export const handleLogoutRediection = async (redirection = LOGIN_ROUTE, returnUrl = '') => {
  const loginRegexRoute = /\/login/i;
  const { pathname } = window.location;

  if (!loginRegexRoute.test(pathname)) {
    removeLocalData();
    if (returnUrl) {
      const validReturnUrl = new URL(returnUrl, window.location.origin).href;

      window.history.replaceState({}, '', validReturnUrl);
      window.location.assign(redirection);
    } else {
      window.location.replace(redirection);
    }
  }
};

export const logOutMain = async () => {
  removeLocalData();
};

export const handleRedirectLogout = async (redirection = LOGIN_ROUTE, shouldRedirect = false, returnUrl = '') => {
  const response = await logout();
  if (response || shouldRedirect) {
    await handleLogoutRediection(redirection, returnUrl);
  }
};

export const handleLogout = async () => {
  await logout();
  removeLocalData();
};
