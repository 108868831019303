export const DefaultTheme = {
  palette: {
    primary: {
      light: '#8d6eff',
      main: '#2C1482',
      dark: '#0014be',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#abb2ce',
      main: '#176BCD',
      dark: '#4f566f',
      contrastText: '#000000',
    },
    tertiary: {
      light: '#abb2ce',
      main: '#FA1959 !important',
      dark: '#4f566f',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        fontFamily: 'Open Sans, sans-serif',
      },
    },
  },
};
