import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCustom } from '../ButtonCustom';

export const WhatsappButton = ({
  children,
}) => {
  const onHandleClick = () => {
    const whatsappURL = 'https://api.whatsapp.com/send?phone=523335063714&text=';
    window.open(whatsappURL, '_blank');
  };
  return (
    <ButtonCustom
      onClick={onHandleClick}
    >
      {children}
    </ButtonCustom>
  );
};

WhatsappButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

WhatsappButton.defaultProps = {
  children: '',
};
