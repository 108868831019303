import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { LandingLayout } from '../../../landing/layouts/LandingLayout';
import {
  InfoTag,
} from '../../../landing/sections';
import { Loader } from '../../../../components';
import { HeaderBar } from '../../../landing/components/HeaderBar';
import { CustomLogo } from '../../../../components/CustomLogo';
import { Perfilador } from '../../../perfilador';

import * as leadsAPI from '../../../../api/leads';
import * as entitiesAPI from '../../../../api/entity';
import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import {
  getActualStep, getConfigTags, storeStepData,
  handleLogout,
} from '../../../../utils';
import { storeCredit } from '../../../../utils/credit';
import { storeFlowData } from '../../../../utils/flows';

import useStyles from './useStyles';
import useLoading from '../../../../hooks/useLoading';
import { useProduct } from '../../../../hooks/useProduct';
import useRoute from '../../../../hooks/useRoute';
import { ThemeContext } from '../../../../context/Theme/themeContext';
import { StepperContext } from '../../../../context/stepper';
import { storeEntityData, storePerfilamientoLeadData } from '../../../../storage';
import { setInitialFieldValues } from '../../../../store/fieldValues';
import {
  CHIPLO_INMOBILIARIA_ID,
  PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS,
} from '../../../../constants';
import { setInitialPerfiladorPrecioInmueble } from '../../../../store/perfilador';

export const InmobiliariaLanding = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loadTheme } = useContext(ThemeContext);
  const { initStepper } = useContext(StepperContext);

  const { getProductByRoute } = useProduct();
  const {
    navigateWithQueryParam,
    location,
  } = useRoute();
  const { pathname } = location;

  const { isLoading, startLoading, endLoading } = useLoading();
  const [inmobiliariaData, setInmobiliariaData] = useState({});
  const [leadData, setLeadData] = useState(null);

  const queryParams = new URLSearchParams(location.search);
  const inmobiliariaId = queryParams.get('inmobiliaria') || CHIPLO_INMOBILIARIA_ID;
  const leadId = queryParams.get('lead') || '';

  const [logoToShow, setLogoToShow] = useState('');

  const featchLeadCreditData = async () => {
    if (!leadId) return;
    const lead = await leadsAPI.getLeadById(leadId);
    if (!lead) return;
    const { extraData: { propiedadValor = '$0' } = {} } = lead;
    setLeadData(lead);
    dispatch(setInitialPerfiladorPrecioInmueble(propiedadValor));
  };

  const handleStoreLeadData = () => {
    if (!leadData) return;
    storePerfilamientoLeadData(leadData);
  };

  const fetchData = async () => {
    try {
      startLoading();
      const dataInmobiliaria = await entitiesAPI.getEntityById(inmobiliariaId);
      await featchLeadCreditData();
      const { logoURL = '', showLogoInCalificador = false } = dataInmobiliaria;
      const logoToDisplay = ((inmobiliariaId !== CHIPLO_INMOBILIARIA_ID) && showLogoInCalificador)
        ? logoURL
        : null;
      setLogoToShow(logoToDisplay);
      setInmobiliariaData(dataInmobiliaria);
      endLoading();
    } catch (e) {
      endLoading();
    }
  };

  const handleSubmit = async ({
    storageData,
    event,
  }) => {
    event.preventDefault();
    event.stopPropagation();
    await handleLogout();
    dispatch(setInitialFieldValues());
    startLoading();
    storeCredit(storageData);
    handleStoreLeadData();
    const pathSegments = pathname.split('/').filter(Boolean);
    const firstSegment = `/${pathSegments[0]}`;
    const productByRoute = getProductByRoute(firstSegment);
    const { name, firstRoute } = productByRoute;
    await loadTheme(name);
    const tags = await getConfigTags(name);
    const stepperObject = { steps: tags, current: 0 };
    initStepper(stepperObject);
    const { steps } = stepperObject;
    const actualStep = getActualStep(steps);
    storeFlowData(productByRoute);
    storeStepData({ ...actualStep, steps });
    storeEntityData(inmobiliariaData);

    googleAnalytics.cotizacion();
    navigateWithQueryParam(firstRoute, 'inmobiliaria');
    endLoading();
  };

  useEffect(() => {
    googleAnalytics.visitaChiplo();
  }, []);

  useEffect(() => {
    fetchData();
  }, [inmobiliariaId]);

  return (
    <LandingLayout>
      <Loader open={isLoading} />
      <HeaderBar />
      <Box className={classes.logoContainer}>
        {
          logoToShow && (
            <CustomLogo customUrl={logoToShow} isLoading={isLoading} />
          )
        }
      </Box>
      <Box className={classes.textsContainer}>
        <Typography
          className={clsx(classes.title, {
            [classes.titleNoLogo]: !logoToShow,
          })}
        >
          Precalificación hipotecaria
        </Typography>
        <Typography
          className={classes.subTitle}
        >
          En 2 minutos sabrás cuánto dinero te prestarían todos los bancos.
        </Typography>
      </Box>
      <Perfilador
        buttonTitle="Ver cotización"
        onSubmit={handleSubmit}
        variant={PERFILADOR_VARIANTE_COTIZADOR_INMOBILIARIAS}
        showFooter
      />
      <InfoTag />
    </LandingLayout>
  );
};
