import { decryptData, encryptData } from './encryption';

const STEP_KEY = btoa('STEP_DATA');

export const storeStepData = (data) => {
  const encryptedData = encryptData(JSON.stringify(data));
  window.localStorage.setItem(STEP_KEY, encryptedData);
};

export const removeStepData = () => {
  window.localStorage.removeItem(STEP_KEY);
};

export const getStepData = () => {
  const jsonDataEncrypted = window.localStorage.getItem(STEP_KEY);
  const jsonData = decryptData(jsonDataEncrypted);
  return JSON.parse(jsonData);
};

const getItem = (key) => {
  try {
    const auth = getStepData();
    return auth[key];
  } catch {
    return undefined;
  }
};

export const getStepRoute = ({ defaultRoute }) => {
  const stepRoute = getItem('stepRoute');
  return stepRoute || defaultRoute;
};

export const getCurrentStep = () => {
  const currentStep = getItem('currentStep');
  return currentStep;
};

export const getSteps = () => {
  const steps = getItem('steps');
  return steps;
};

export const isAllowCurrentStep = (step) => {
  const currentStep = getCurrentStep();
  return step === currentStep;
};

export const getProduct = () => {
  const product = getItem('product');
  return product;
};

export const getStepByStepPosition = (currentStepPosition) => {
  const steps = getItem('steps');
  const currentStepData = steps.find(({ stepPosition }) => (stepPosition === currentStepPosition));
  return currentStepData;
};
