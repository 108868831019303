import React, {
  useEffect, useContext, useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';

// import ReactGA from 'react-ga';
import useStateMachine from './hooks/useStateMachine';

import { StepperContext } from '../../../../context/stepper';
import { LoaderPage } from './LoaderPage';
import {
  PERFILAMIENTO,
  PERFILAMIENTO_CREDITICIO_NAME,
  PERFILAMIENTO_NAME,
  PROFILING_COTIZATION,
  PROFILING_MODAL,
  PROFILING_COMPLETED,
  PROFILING_EMPTY_MATRIZ,
  PROFILING_UPLOADING,
  CREDIT_PROFILING_COTIZATION_STRATEGY,
  STEP_INFORMACION_DEL_CLIENTE,
  PERFILAMIENTO_MODAL_COMPLETE,
  NORMALIZED_STEP_INFORMACION_DEL_CLIENTE,
} from '../../../../constants';

import { Profiling } from './Profiling';
import {
  getCurrentStep,
  getProcess,
  getPageTagStepsByLocalSteps,
  getTheCurrentStep,
  updateLocalStepsByStep,
  createUpdateTagGeneral,
  execFinishTagConfigDatosDocumentos,
  getFlowTypeHandler,
} from '../../../../utils';

import { getAndSetStrategyByName } from '../../../../utils/strategies.utils';
import * as processAPI from '../../../../api/process';
import { CreditProfilingUpload } from './CreditProfilingUpload';
import { CreditProfilingWait } from './CreditProfilingWait';
import useRoute from '../../../../hooks/useRoute';

import { useProfiling } from '../../../../hooks/useProfiling';
import { StepConfigCompletedChecker } from '../../../../components/StepConfigCompletedChecker';
import { nowDate } from '../../../../components/Input/InputBaseDatePicker/formatter';
import * as googleAnalytics from '../../../../utils/googleAnalytics/events';
import { ModalNodosChiplo } from './CreditProfilingModalChiplo';
import { finishStepByProcess } from '../../../../utils/steps';
import { CreditProfilingCotizationV2 } from './CreditProfilingCotizationV2';

export const CreditProfilingPage = ({ strategyName, productStrategy }) => {
  const { strategy, changeStrategy } = useProfiling();

  const [title, setTitle] = useState('');
  const [stepperTags, setStepperTags] = useState({});
  const { updateCurrentStep } = useContext(StepperContext);
  const {
    navigateWithQueryParam,
  } = useRoute();
  const {
    STATES,
    currentState,
    goToProfilingModal,
    goToProfilingCotization,
    goToProfiling,
    goToProfilingUploading,
    goToLoaderPage,
    goToProfilingCompleted,
  } = useStateMachine();

  const finishUpload = () => {
    setTitle('¡Gracias! Revisaremos la solicitud y regresamos contigo muy pronto.');
    goToProfilingCompleted();
  };

  const emptyMatrix = () => {
    setTitle('En este momento no podemos hacerte un oferta por que no eres sujeto a crédito. Te sugerimos intentar de nuevo en 90 días');
    return goToProfilingCompleted();
  };

  const finishProfiling = (finishType) => {
    switch (finishType) {
      case PROFILING_COTIZATION:
        getAndSetStrategyByName(
          CREDIT_PROFILING_COTIZATION_STRATEGY,
          productStrategy,
          changeStrategy,
        );
        return goToProfilingCotization();
      case PROFILING_EMPTY_MATRIZ:
      default:
        return emptyMatrix();
    }
  };

  const finishInformacionCliente = async () => {
    const processId = getProcess();
    const { normalizedFlowName } = await getFlowTypeHandler(processId);
    await execFinishTagConfigDatosDocumentos(normalizedFlowName);
    await finishStepByProcess(NORMALIZED_STEP_INFORMACION_DEL_CLIENTE);
    setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
    return goToProfilingCompleted();
  };

  const completeUploadTagGeneral = () => {
    createUpdateTagGeneral(
      {
        completed: true,
        fecha: nowDate(),
      },
      'solicitudfirmada',
    );
    googleAnalytics.solicitudFirmada();
    window.location.reload();
  };

  const createUpdateTag = useCallback((updateTag) => {
    const process = getProcess();
    if (!process) {
      const localStepPosition = getCurrentStep();
      const { steps } = updateLocalStepsByStep(localStepPosition);
      navigateWithQueryParam(steps[localStepPosition + 1].route, 'inmobiliaria');
    }
    return async () => {
      const { tags = {} } = await processAPI.getProcessById(process);
      const body = {
        [PERFILAMIENTO_NAME]: { ...tags[PERFILAMIENTO_NAME], ...updateTag },
      };
      await processAPI.createTag({
        tagName: PERFILAMIENTO_NAME,
        active: true,
        body: { ...tags, ...body },
        processId: process,
      });
    };
  }, [stepperTags]);

  const getProcessSteps = async () => {
    const processId = getProcess();
    const localStepPosition = getCurrentStep();

    if (!processId) {
      return getPageTagStepsByLocalSteps(
        PERFILAMIENTO_CREDITICIO_NAME,
        localStepPosition,
      );
    }
    const process = await processAPI.getProcessById(processId);
    const { tags = {} } = process;
    const { redirectPath, stepPosition } = await getTheCurrentStep(
      processId,
      PERFILAMIENTO_CREDITICIO_NAME,
    );
    const consultTag = tags[PERFILAMIENTO_CREDITICIO_NAME];
    return {
      tags, redirectPath, stepPosition, consultTag, process,
    };
  };

  const selectPage = async () => {
    const {
      tags, redirectPath, stepPosition, consultTag, process = {},
    } = await getProcessSteps();
    const { currentPage = PERFILAMIENTO, completed } = consultTag;
    if (completed) return navigateWithQueryParam(redirectPath, 'inmobiliaria');
    setStepperTags(tags);
    updateCurrentStep(stepPosition);
    switch (currentPage) {
      case PERFILAMIENTO:
        return goToProfiling();
      case PROFILING_MODAL:
        const { modalViewFinish = false } = process;
        if (modalViewFinish) {
          setTitle('Tu expediente está en proceso de revisión. Una vez revisado nos pondremos en contacto. ');
          return goToProfilingCompleted();
        }
        return goToProfilingModal();
      case PERFILAMIENTO_MODAL_COMPLETE:
        setTitle('Tu expediente está en proceso de revisión. Una vez revisado nos pondremos en contacto. ');
        return goToProfilingCompleted();

      case PROFILING_EMPTY_MATRIZ:
        setTitle('En este momento no podemos hacerte un oferta por que no eres sujeto a crédito. Te sugerimos intentar de nuevo en 90 días');
        return goToProfilingCompleted();
      case PROFILING_COMPLETED:
        setTitle('Gracias, información recibida. Analizamos tu solicitud de inmediato y regresamos contigo con los avances. Cualquier duda puedes escribirnos al 3335063714.');
        return goToProfilingCompleted();
      case PROFILING_COTIZATION:
        getAndSetStrategyByName(
          CREDIT_PROFILING_COTIZATION_STRATEGY,
          productStrategy,
          changeStrategy,
        );
        return goToProfilingCotization();
      case PROFILING_UPLOADING:
        return goToProfilingUploading();
      default:
        return goToLoaderPage();
    }
  };

  useEffect(() => {
    selectPage();
  }, []);

  useEffect(() => {
    if (strategy) return;
    getAndSetStrategyByName(strategyName, productStrategy, changeStrategy);
  }, [productStrategy, changeStrategy]);

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      {
        (
          () => {
            switch (currentState) {
              case STATES.LOADER_PAGE:
                return (
                  <LoaderPage />
                );
              case STATES.PROFILING_MODAL:
                return (
                  <StepConfigCompletedChecker
                    tagName={PERFILAMIENTO_CREDITICIO_NAME}
                    stepConfigName={STEP_INFORMACION_DEL_CLIENTE}
                    updateTag={{ currentPage: PERFILAMIENTO_MODAL_COMPLETE }}
                    onStepConfigCompleted={completeUploadTagGeneral}
                  >
                    <ModalNodosChiplo
                      onFinish={finishInformacionCliente}
                    />
                  </StepConfigCompletedChecker>
                );
              case STATES.PROFILING_COMPLETED:
                return (
                  <CreditProfilingWait titlePage={title} />
                );
              case STATES.PROFILING_COTIZATION:
                return (
                  <CreditProfilingCotizationV2
                    createUpdateTag={createUpdateTag}
                    goTo={emptyMatrix}
                  />
                );
              case STATES.PROFILING_UPLOADING:
                return (
                  <StepConfigCompletedChecker
                    tagName={PERFILAMIENTO_CREDITICIO_NAME}
                    stepConfigName={STEP_INFORMACION_DEL_CLIENTE}
                    updateTag={{ currentPage: PROFILING_COMPLETED }}
                    onStepConfigCompleted={completeUploadTagGeneral}
                  >
                    <CreditProfilingUpload
                      createUpdateTag={createUpdateTag({ currentPage: PROFILING_COMPLETED })}
                      goTo={finishUpload}
                    />
                  </StepConfigCompletedChecker>
                );
              case STATES.PROFILING:
              default:
                return (
                  <Profiling
                    createUpdateTag={createUpdateTag}
                    goTo={finishProfiling}
                  />
                );
            }
          }
        )()
      }
    </>
  );
};

CreditProfilingPage.propTypes = {
  productStrategy: PropTypes.string,
  strategyName: PropTypes.string,
};

CreditProfilingPage.defaultProps = {
  strategyName: '',
  productStrategy: '',
};
