import { useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { INIT_STEPPER, UPDATE_CURRENT_STEP, UPDATE_STATE_STEPPER } from './constants';
import { StepperContext } from './stepperContext';
import { stepperReducer } from './stepperReducer';
import { getCurrentStep, getSteps } from '../../utils/stepper';

const STEPPER_INITIAL_STATE = {
  steps: [],
  current: 0,
};

const INIT = () => {
  const steps = getSteps() || [];
  const current = getCurrentStep() || 0;
  return {
    steps,
    current,
  };
};

export const StepperProvider = ({ children }) => {
  const [state, dispatch] = useReducer(stepperReducer, STEPPER_INITIAL_STATE, INIT);

  const initStepper = useCallback((payload) => {
    dispatch({ type: INIT_STEPPER, payload });
  }, []);

  const updateCurrentStep = useCallback((currentStep) => {
    dispatch({ type: UPDATE_CURRENT_STEP, payload: currentStep });
  }, []);

  const updateStateStepper = useCallback((completed) => {
    dispatch({ type: UPDATE_STATE_STEPPER, payload: completed });
  }, []);

  const value = useMemo(
    () => ({
      state,
      initStepper,
      updateCurrentStep,
      updateStateStepper,
    }),
    [
      state,
      initStepper,
      updateCurrentStep,
      updateStateStepper,
    ],
  );

  return (
    <StepperContext.Provider
      value={value}
    >
      { children }
    </StepperContext.Provider>
  );
};

StepperProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};
