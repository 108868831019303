import moment from 'moment';
import * as googleAPIS from '../api/googleApis';
import 'moment/locale/es';
import { ALLOWED_CPS_FIELDS_NAMES, DEFAUT_FIELD_UPLOAD_LABEL } from '../constants';
import { DEFAULT_FORMAT_FILES_TO_UPLOAD, FILE_TYPE_LABELS } from '../constants/format';

const LOCALE = 'es';
moment.locale(LOCALE);

export const extractAddressFromPostalCode = async (codigoPostal, filteredNewNodes) => {
  const {
    state,
    country,
    ciudad,
    municipio,
    colonia,
  } = await googleAPIS.getAddressData(codigoPostal);
  const address = {
    estado: state,
    pais: country,
    ciudad,
    municipio,
    colonia,
  };
  return filteredNewNodes.map((filteredNewNode) => {
    const { fields } = filteredNewNode;
    const newFields = fields.map((field) => {
      const { name, value } = field;
      return (address[name] && !value) ? { ...field, value: address[name] } : { ...field };
    });
    return { ...filteredNewNode, fields: newFields };
  });
};

export const getNewNodes = (nodesWithFieldValues) => {
  const newNodes = nodesWithFieldValues.map((node) => {
    const { fields } = node;
    const filteredFields = fields.filter(({ config: { hide = false } }) => hide === false);
    return { ...node, fields: filteredFields };
  });
  return newNodes;
};

export const extractDateFromRFC = (rfc) => {
  const año = rfc.substr(4, 2);
  const mes = rfc.substr(6, 2);
  const dia = rfc.substr(8, 2);
  const siglo = (parseInt(año, 10) <= 21) ? '20' : '19';
  return {
    año: `${siglo}${año}`, mes, dia,
  };
};

export const giveFormatDate = ({
  año, mes, dia,
}) => moment(`${año}${mes}${dia}`).format('D [de] MMM [de] YYYY');

export const obtenerFechaDeNacimiento = (rfc, filteredNewNodes) => {
  if (rfc.length < 10) return filteredNewNodes;
  const dateExtracted = extractDateFromRFC(rfc);
  const result = giveFormatDate(dateExtracted);
  const address = {
    fechadenac: result,
  };
  return filteredNewNodes.map((filteredNewNode) => {
    const { fields } = filteredNewNode;
    const newFields = fields.map((field) => {
      const { name } = field;
      return (address[name]) ? { ...field, value: address[name] } : { ...field };
    });
    return { ...filteredNewNode, fields: newFields };
  });
};

export const get1DNodesFields = (nodesWithFields) => {
  const onlyFields = nodesWithFields.reduce((acc, curr) => {
    const { fields = [] } = curr;
    return [...acc, ...fields];
  }, []);
  return onlyFields;
};

export const get1DFieldsFromNodeData = (nodesWithFields) => {
  const onlyFields = nodesWithFields.reduce((acc, curr) => {
    const { data = [] } = curr;
    return [...acc, ...data];
  }, []);
  return onlyFields;
};

export const filterDocumentFields = (fields) => fields.filter(({ fieldType: { name = '' } = {} }) => name !== 'document');

export const getOnlyDocFields = (fields) => fields.filter(({ fieldType: { name = '' } = {} }) => name === 'document');

export const getOrFilterDisabledModalFields = (fields, onlyDisabled = false) => fields
  .filter(
    ({ config: { disabledModal = false } = {} }) => (onlyDisabled ? disabledModal : !disabledModal),
  );

export const markDocumentsHasReversePhoto = (fields) => {
  const newFields = fields.map((field) => {
    const { documentCatalog = {} } = field;
    if (documentCatalog?.reversePhoto) return { ...field, reversePhoto: true };
    return field;
  });
  return newFields;
};

export const getNodeDocumentsFieldsSorted = (node) => {
  const { fields } = node;
  const docFields = getOnlyDocFields(fields);
  const onlyFields = filterDocumentFields(fields);

  return {
    ...node,
    fields: [...onlyFields, ...docFields],
  };
};

export const getNodeDocumentsByFieldsDocuments = (nodesWithFields, nodeName) => {
  const onlyFields = get1DNodesFields(nodesWithFields);
  const docFields = getOnlyDocFields(onlyFields);
  if (!docFields.length) return nodesWithFields;
  const nodesWithoutDocsFields = nodesWithFields.map((node) => ({
    ...node,
    fields: filterDocumentFields(node.fields),
  }));
  const nodeDocs = {
    name: nodeName,
    fields: docFields,
    fieldsData: docFields,
  };
  return [...nodesWithoutDocsFields, nodeDocs];
};

export const isParentField = (field = {}) => (
  Boolean(field?.config?.children)
);

const isChildField = (field, parentField) => {
  const childrenIds = parentField.config.children || [];
  return childrenIds.includes(field._id);
};

export const compareValueToParentValue = (value, parentValue) => {
  if (Array.isArray(value)) {
    const optionsChequed = value.filter(({ checked }) => checked);
    const valueNames = optionsChequed.map(({ name }) => name);
    return valueNames.includes(parentValue);
  }
  return parentValue === value;
};

export const shouldShowChildField = (parentFieldValue, childField) => {
  const childParentValue = childField.config.parentValue || '';
  return compareValueToParentValue(parentFieldValue, childParentValue);
};

const updateFieldVisibility = (field, isVisible) => ({
  ...field,
  config: {
    ...field.config,
    hide: !isVisible,
    temporalRequired: isVisible ? field.config.hideRequired : undefined,
    required: field?.config?.hideRequired || field.config?.required,
  },
});

export const getUnhiddenFieldsWithChildren = (fields) => {
  const childrenFieldsToShow = new Set();

  fields.forEach((parentField) => {
    if (isParentField(parentField)) {
      const parentFieldValue = parentField.value || '';
      fields.forEach((childField) => {
        if (
          isChildField(childField, parentField)
          && shouldShowChildField(parentFieldValue, childField)
        ) {
          childrenFieldsToShow.add(childField._id);
        }
      });
    }
  });

  return fields.map((field) => (childrenFieldsToShow.has(field._id)
    ? updateFieldVisibility(field, true)
    : field));
};

export const changeHideChildrensByFieldValue = (
  field,
  fields,
  updateValueOnDocumentNode = () => {},
) => {
  if (!field) return fields;
  const children = field?.config?.children || [];
  const updatedFields = fields.map((_field) => {
    if (children.includes(_field._id)) {
      const parentValue = field.value;
      const hide = !compareValueToParentValue(parentValue, _field?.config?.parentValue);
      const newValue = hide ? '' : _field.value || '';
      if (newValue !== _field?.value) updateValueOnDocumentNode(_field, newValue);
      return {
        ..._field,
        value: newValue,
        config: {
          ..._field.config,
          hide,
          required: !hide
            ? _field?.config?.hideRequired
            : _field.config.required,
        },
      };
    }
    return _field;
  });
  return updatedFields;
};

const isDisabledField = (config) => config?.disabled || config?.disabledModal || false;

export const applyDissabledModalFields = (fields) => fields.map((field) => {
  const { config = {} } = field;
  return {
    ...field,
    config: {
      ...config,
      disabled: isDisabledField(config),
    },
  };
});

export const dissableModalDisabledFieldsInNodes = (nodes) => nodes.map((node) => ({
  ...node,
  fields: applyDissabledModalFields(node.fields),
}));

export const filterHiddenFields = (fields) => fields.filter(({ config = {} }) => !config?.hide);

export const checkIsNodeNotComplete = (node) => {
  const { fields = [] } = node;
  return fields.some(({ config = {}, value = '' }) => {
    const { required = false, hide = false } = config;
    if (!hide && !required) return false;
    if (hide || !required) return false;
    if (config?.childrenNodes && Array.isArray(value)) {
      return !value.some(({ checked }) => checked);
    }
    return value === '' || value === null || !value.length;
  });
};

export const indexFieldsByFieldId = (fields) => fields.reduce((acc, curr) => {
  acc[curr._id] = curr;
  return acc;
}, {});

export const mapFieldsToFieldValuesProps = ({
  fieldsWithValues,
  fieldsIndexed,
  processId,
  includeFieldName = false,
}) => fieldsWithValues.map(({ _id, value = '' }) => ({
  field: _id,
  value,
  process: processId,
  config: fieldsIndexed[_id]?.config || {},
  ...(includeFieldName && { name: fieldsIndexed[_id]?.name }),
}));

export const isActionFillCPField = (fieldName, value) => (ALLOWED_CPS_FIELDS_NAMES
  .includes(fieldName) && value && value.length === 5);

export const isValueAValidCharacter = (value) => {
  if (!value || value === '' || value === null || !value.length) return false;
  const regex = /^[a-zA-ZÀ-ÿ0-9._%+\-@ ]+$/;
  return typeof value === 'string' && value.trim() !== '' && regex.test(value);
};

/**
 * Gets the allowed file formats for uploading based on the accepted file types.
 * @param {string[]} filesToAccept - List of accepted file types.
 * @returns {object} Allowed file formats for uploading.
 * @example getFilesFormatToUpload(['jpg', 'png']);
 */
export const getFilesFormatToUpload = (filesToAccept = []) => {
  if (!filesToAccept.length) {
    return DEFAULT_FORMAT_FILES_TO_UPLOAD;
  }

  const foundFormat = Object.entries(DEFAULT_FORMAT_FILES_TO_UPLOAD)
    .filter(([, extensions]) => filesToAccept.some((fileType) => extensions.includes(`.${fileType}`)))
    .reduce((acc, [mimeType, extensions]) => ({ ...acc, [mimeType]: extensions }), {});

  return foundFormat;
};

/**
 * Generates a label for file upload based on accepted file types.
 * @param {string[]} filesToAccept - List of accepted file types.
 * @returns {string} Generated file upload label.
 */
export const getFileUploadLabelBasedOnUploadFormats = (filesToAccept = []) => {
  if (!filesToAccept.length) return DEFAUT_FIELD_UPLOAD_LABEL;

  const labelSet = new Set(
    filesToAccept.map((fileType) => FILE_TYPE_LABELS[fileType] || fileType),
  );

  const uniqueLabels = Array.from(labelSet);

  switch (uniqueLabels.length) {
    case 0:
      return DEFAUT_FIELD_UPLOAD_LABEL;
    case 1:
      return `Agrega ${uniqueLabels[0]}`;
    default:
      const lastLabel = uniqueLabels.pop();
      return `Agrega ${uniqueLabels.join(', ')} o ${lastLabel}`;
  }
};
